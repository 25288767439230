import React, { Component } from "react";
import { ProductSurveyStates } from "./types/ProductSurveyTypes";
import { withRouter } from "../../hoc/withRouter";
import FooterSection from "../../component/pageSection/FooterSection";
import { Breadcrumb, Col, Divider, Row, Spin, Image, Form, Radio, Space, Card, Checkbox, Input, Button, Modal, Result } from "antd";
import Cookies from "universal-cookie";
import TextArea from "antd/es/input/TextArea";
import CurrencyInput from "react-currency-input-field";
import { vehicleServices } from "../../services/autotrimitra/VehicleServices";
import { crmAmartaVipServices } from "../../services/amartaVip/CrmAmartaVipServices";
import dayjs from "dayjs";

class ProductSurvey extends Component<any, ProductSurveyStates> {
  private cookies = new Cookies();
  private readonly initState!: ProductSurveyStates;

  constructor(props: any) {
    super(props);

    const { match } = this.props;
    const code = match.params.code;
    this.initState = {
      isDesktop: false,
      fetching: false,
      complete: false,
      saving: false,
      code: code,
      modelUid: "",
      dataVehicle: {},
      dataTestDrive: {},
      dataVehicleName: "",
      dataVehicleSpec: [],
      dataVehicleImage: [],
      interiorSatisfied: "",
      interiorDissatisfied: "",
      exteriorSatisfied: "",
      exteriorDissatisfied: "",
      surveyRecommended: "",
      surveyLuxury: null,
      surveyFeminine: null,
      surveyUserAge: null,
      surveyRoadField: null,
      surveyBusinessUsage: null,
      surveyTechnology: null,
      testDriveResult: "",
      vehicleUsage: null,
      purchasePlan: null,
      purchaseMethod: null,
      downPayment: 0,
      tenor: 0,
      installmentAmount: 0,

      countCheckedBuyFactor: 0,
      checkBoxInterior: false,
      checkBoxExterior: false,
      checkBoxFeature: false,
      checkBoxBrand: false,
      checkBoxFuelConsumption: false,
      checkBoxPrice: false,
      checkBoxPerform: false,
      checkBoxComfort: false,
      checkBoxDurable: false,
      checkBoxAfterSales: false,
      checkBoxCabinRelief: false,
      checkBoxSparePart: false,
      checkBoxTechnology: false,
      checkBoxSecurity: false,
      checkBoxOther: false,
      checkBoxOtherText: "",

      countCheckedInfo: 0,
      checkBoxInfoTv: false,
      checkBoxInfoShoppingMall: false,
      checkBoxInfoOfficialWebsite: false,
      checkBoxInfoInternet: false,
      checkBoxInfoPhone: false,
      checkBoxInfoMagazine: false,
      checkBoxInfoSocialMedia: false,
      checkBoxInfoExhibition: false,
      checkBoxInfoBillboard: false,
      checkBoxInfoRelatives: false,
      checkBoxInfoClub: false,
      checkBoxInfoDealer: false,
      checkBoxInfoOther: false,
      checkBoxInfoOtherText: ""
    };
    this.state = { ...this.initState };
  }

  handleResize = () => {
    if (window.innerWidth < 992) {
      this.setState({
        isDesktop: false
      });
    } else {
      this.setState({
        isDesktop: true
      });
    }
  };

  blurDownPayment = () => {
    let dpChosen = Math.round(this.state.downPayment / 1000000) * 1000000;
    this.setState({
      downPayment: dpChosen
    });
  };

  blurInstallmentAmount = () => {
    let dpChosen = Math.round(this.state.installmentAmount / 100000) * 100000;
    this.setState({
      installmentAmount: dpChosen
    });
  };

  blurTenor = () => {
    if (Number(this.state.tenor) > 100) {
      this.setState({
        tenor: 100
      });
    }
  };


  onFieldChange = async <T extends keyof Pick<any, any>>(target: T, value: any, countX: any = null) => {
    const currentState: any = { ...this.state };
    switch (target) {
      case "checkBoxInfoTv":
        currentState.checkBoxInfoTv = value;
        break;
      case "checkBoxInfoShoppingMall":
        currentState.checkBoxInfoShoppingMall = value;
        break;
      case "checkBoxInfoOfficialWebsite":
        currentState.checkBoxInfoOfficialWebsite = value;
        break;
      case "checkBoxInfoInternet":
        currentState.checkBoxInfoInternet = value;
        break;
      case "checkBoxInfoPhone":
        currentState.checkBoxInfoPhone = value;
        break;
      case "checkBoxInfoMagazine":
        currentState.checkBoxInfoMagazine = value;
        break;
      case "checkBoxInfoSocialMedia":
        currentState.checkBoxInfoSocialMedia = value;
        break;
      case "checkBoxInfoExhibition":
        currentState.checkBoxInfoExhibition = value;
        break;
      case "checkBoxInfoBillboard":
        currentState.checkBoxInfoBillboard = value;
        break;
      case "checkBoxInfoRelatives":
        currentState.checkBoxInfoRelatives = value;
        break;
      case "checkBoxInfoClub":
        currentState.checkBoxInfoClub = value;
        break;
      case "checkBoxInfoDealer":
        currentState.checkBoxInfoDealer = value;
        break;
      case "checkBoxOtherText":
        currentState.checkBoxOtherText = value;
        break;
      case "checkBoxOther":
        currentState.checkBoxOther = value;
        break;
      case "checkBoxFeature":
        currentState.checkBoxFeature = value;
        break;
      case "checkBoxBrand":
        currentState.checkBoxBrand = value;
        break;
      case "checkBoxFuelConsumption":
        currentState.checkBoxFuelConsumption = value;
        break;
      case "checkBoxPrice":
        currentState.checkBoxPrice = value;
        break;
      case "checkBoxPerform":
        currentState.checkBoxPerform = value;
        break;
      case "checkBoxComfort":
        currentState.checkBoxComfort = value;
        break;
      case "checkBoxDurable":
        currentState.checkBoxDurable = value;
        break;
      case "checkBoxAfterSales":
        currentState.checkBoxAfterSales = value;
        break;
      case "checkBoxCabinRelief":
        currentState.checkBoxCabinRelief = value;
        break;
      case "checkBoxSparePart":
        currentState.checkBoxSparePart = value;
        break;
      case "checkBoxTechnology":
        currentState.checkBoxTechnology = value;
        break;
      case "checkBoxSecurity":
        currentState.checkBoxSecurity = value;
        break;
      case "downPayment":
        currentState.downPayment = value;
        break;
      case "tenor":
        currentState.tenor = value;
        break;
      case "installmentAmount":
        currentState.installmentAmount = value;
        break;
      case "interiorSatisfied":
        currentState.interiorSatisfied = value;
        break;
      case "interiorDissatisfied":
        currentState.interiorDissatisfied = value;
        break;
      case "exteriorSatisfied":
        currentState.exteriorSatisfied = value;
        break;
      case "exteriorDissatisfied":
        currentState.exteriorDissatisfied = value;
        break;
      case "surveyLuxury":
        currentState.surveyLuxury = value;
        break;
      case "surveyFeminine":
        currentState.surveyFeminine = value;
        break;
      case "surveyUserAge":
        currentState.surveyUserAge = value;
        break;
      case "surveyRoadField":
        currentState.surveyRoadField = value;
        break;
      case "surveyBusinessUsage":
        currentState.surveyBusinessUsage = value;
        break;
      case "surveyTechnology":
        currentState.surveyTechnology = value;
        break;
      case "surveyRecommended":
        currentState.surveyRecommended = value;
        break;
      case "testDriveResult":
        currentState.testDriveResult = value;
        break;
      case "vehicleUsage":
        currentState.vehicleUsage = value;
        break;
      case "purchasePlan":
        currentState.purchasePlan = value;
        break;
      case "purchaseMethod":
        currentState.purchaseMethod = value;
        break;
      case "checkBoxInterior":
        currentState.checkBoxInterior = value;
        break;
      case "checkBoxExterior":
        currentState.checkBoxExterior = value;
        break;
      case "checkBoxInfoOther":
        currentState.checkBoxInfoOther = value;
        break;
      case "checkBoxInfoOtherText":
        currentState.checkBoxInfoOtherText = value;
        break;
    }

    let latestCountInfo = this.state.countCheckedInfo;
    if (countX === "checkInfo") {
      latestCountInfo += value ? 1 : -1;
      currentState.countCheckedInfo = latestCountInfo;
    }
    if (latestCountInfo > 5) {
      return Modal.error({ title: "Error", content: "maksimal sumber informasi yang dapat dipilih adalah 5 item" });
    }

    let latestCountFactor = this.state.countCheckedBuyFactor;
    if (countX === "countFactor") {
      latestCountFactor += value ? 1 : -1;
      currentState.countCheckedBuyFactor = latestCountFactor;
    }
    if (latestCountFactor > 5) {
      return Modal.error({ title: "Error", content: "maksimal faktor pertimbangan yang dapat dipilih adalah 5 item" });
    }

    await this.promisedSetState({
      ...currentState
    });
  };

  async componentDidMount() {
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }, 10);

    window.addEventListener("resize", this.handleResize.bind(this));
    this.handleResize();

    this.setState({
      fetching: true
    });

    try {
      const fetchTestDrive = await crmAmartaVipServices.getTestDrive({ code: this.state.code });
      const testDriveData = fetchTestDrive.data.data[0];
      await this.promisedSetState({
        modelUid: testDriveData.model_uuid,
        dataTestDrive: testDriveData
      });

      if (testDriveData.customer_survey_code) {
        await this.promisedSetState({
          fetching: false,
          complete: true
        });
      }

    } catch (e) {
      console.log(e);
    }

    try {
      const fetchModel = await vehicleServices.getModelList({ model_uuid: this.state.modelUid });
      const modelData = fetchModel.data.data[0];
      const listImage = [modelData?.url_display_assets?.[0]?.url_asset, modelData?.url_display_assets?.[1]?.url_asset, modelData?.url_display_assets?.[2]?.url_asset, modelData?.url_display_assets?.[3]?.url_asset];
      const listSpec = [
        `Class: ${modelData.class}`,
        `Engine Capacity: ${modelData.engine_capacity}`,
        `Doors: ${modelData.doors}`,
        `Seat: ${modelData.chairs?.toString()}`
      ];

      await this.promisedSetState({
        dataVehicleName: modelData.market_name,
        dataVehicleImage: listImage,
        dataVehicleSpec: listSpec,
        dataVehicle: modelData,
        fetching: false
      });

    } catch (e) {
      console.log(e);
    }
  }

  savingData = async () => {
    let warnNotice = "";
    if (!this.state.testDriveResult) {
      warnNotice += "Pengalaman setelah melakukan testDrive, ";
    }
    if (!this.state.interiorSatisfied) {
      warnNotice += "Hal yang disukai dari interior, ";
    }
    if (!this.state.interiorDissatisfied) {
      warnNotice += "Hal yang tidak disukai dari interior, ";
    }
    if (!this.state.surveyRecommended) {
      warnNotice += "Nilai yang direkomendasikan, ";
    }
    if (!this.state.exteriorSatisfied) {
      warnNotice += "Hal yang disukai dari exterior, ";
    }
    if (!this.state.exteriorDissatisfied) {
      warnNotice += "Hal yang tidak disukai dari exterior, ";
    }
    if (!this.state.surveyLuxury || !this.state.surveyFeminine || !this.state.surveyUserAge || !this.state.surveyRoadField || !this.state.surveyBusinessUsage || !this.state.surveyTechnology) {
      warnNotice += "point di bagian Survey Keseluruhan, ";
    }
    if (!this.state.countCheckedBuyFactor) {
      warnNotice += "pilih minimal 1 point dari faktor yang dipertimbangkan ketika membeli kendaraan, ";
    }
    if (!this.state.countCheckedInfo) {
      warnNotice += "pilih minimal 1 point dari sumber informasi, ";
    }
    if (!this.state.vehicleUsage) {
      warnNotice += "Rencana penggunaan, ";
    }
    if (!this.state.purchasePlan) {
      warnNotice += "Rencana Pembelian, ";
    }

    if (warnNotice !== "") {
      const objError = warnNotice.split(",");
      Modal.error({
        title: "Proses Gagal",
        content: (<div>
          Mohon Lengkapi data-data berikut: <br /><br />
          {objError.map(function(itemError, i) {
            return <div key={i}>{itemError}</div>;
          })}
        </div>)
      });
      return false;
    }

    const dataSurvey = {
      activity_type: "CUSTOMERSURVEY",
      company_code: this.state.dataTestDrive.company_code,
      activity_date: dayjs().format("YYYY-MM-DD"),
      activity_time: dayjs().format("HH:mm"),
      activity_name: "-",
      source: "crm.amarta.vip",

      testdrive_code: this.state.code,
      agent_code: this.state.dataTestDrive.agen_code,
      agent_name: this.state.dataTestDrive.agen_name,
      vehicle: {
        brand_name: this.state.dataVehicle.brand_name,
        brand_uuid: this.state.dataVehicle.brand_uuid,
        model_uuid: this.state.dataVehicle.model_uuid,
        market_name: this.state.dataVehicle.market_name,
        model_name: this.state.dataVehicle.model_name
      },
      test_drive_experience: this.state.testDriveResult,
      desire_to_recommend: this.state.surveyRecommended,
      interior_evaluation: {
        interior_satisfied: this.state.interiorSatisfied,
        interior_dissatisfied: this.state.interiorDissatisfied
      },
      exterior_evaluation: {
        exterior_satisfied: this.state.exteriorSatisfied,
        exterior_dissatisfied: this.state.exteriorDissatisfied
      },
      opinion_evaluation: {
        luxury: this.state.surveyLuxury,
        feminine: this.state.surveyFeminine,
        user_age: this.state.surveyUserAge,
        road_field: this.state.surveyRoadField,
        business_usage: this.state.surveyBusinessUsage,
        technology: this.state.surveyTechnology
      },
      consider_buying_factor: {
        interior: this.state.checkBoxInterior,
        exterior: this.state.checkBoxExterior,
        feature: this.state.checkBoxFeature,
        brand: this.state.checkBoxBrand,
        fuel_consumption: this.state.checkBoxFuelConsumption,
        price: this.state.checkBoxPrice,
        perform: this.state.checkBoxPerform,
        comfort: this.state.checkBoxComfort,
        durable: this.state.checkBoxDurable,
        after_sales: this.state.checkBoxAfterSales,
        cabin_relief: this.state.checkBoxCabinRelief,
        spare_part: this.state.checkBoxSparePart,
        technology: this.state.checkBoxTechnology,
        security: this.state.checkBoxSecurity,
        other: this.state.checkBoxOther,
        other_text: this.state.checkBoxOtherText
      },
      source_of_information: {
        tv: this.state.checkBoxInfoTv,
        shopping_mall: this.state.checkBoxInfoShoppingMall,
        official_website: this.state.checkBoxInfoOfficialWebsite,
        internet: this.state.checkBoxInfoInternet,
        phone: this.state.checkBoxInfoPhone,
        magazine: this.state.checkBoxInfoMagazine,
        social_media: this.state.checkBoxInfoSocialMedia,
        exhibition: this.state.checkBoxInfoExhibition,
        billboard: this.state.checkBoxInfoBillboard,
        relatives: this.state.checkBoxInfoRelatives,
        club: this.state.checkBoxInfoClub,
        dealer: this.state.checkBoxInfoDealer,
        other: this.state.checkBoxInfoOther,
        other_text: this.state.checkBoxInfoOtherText
      },
      vehicle_usage: this.state.vehicleUsage,
      purchase_plan: this.state.purchasePlan,
      purchase_method: this.state.purchaseMethod,
      down_payment: this.state.downPayment,
      tenor: this.state.tenor,
      installment_amount: this.state.installmentAmount
    };

    this.setState({
      saving: true
    });

    await crmAmartaVipServices
      .postActivity(dataSurvey)
      .then((result) => {
        this.setState({
          complete: true
        });
      })
      .catch((error) => {
        this.setState({
          saving: false
        });
        Modal.error({ title: "Error", content: error.toString() });
      });
  };

  promisedSetState = (newState: any) => new Promise((resolve: any) => this.setState(newState, resolve));

  render() {
    const surveyOptions = [{ value: 1, label: "Sederhana" }, { value: 2, label: "" }, { value: 3, label: "" }, { value: 4, label: "" }, { value: 5, label: "Mewah" }];
    const surveyOptions2 = [{ value: 1, label: "Maskulin" }, { value: 2, label: "" }, { value: 3, label: "" }, { value: 4, label: "" }, { value: 5, label: "Feminim" }];
    const surveyOptions3 = [{ value: 1, label: "Muda" }, { value: 2, label: "" }, { value: 3, label: "" }, { value: 4, label: "" }, { value: 5, label: "Dewasa" }];
    const surveyOptions5 = [{ value: 1, label: "Perkotaan" }, { value: 2, label: "" }, { value: 3, label: "" }, { value: 4, label: "" }, { value: 5, label: "Off Road" }];
    const surveyOptions6 = [{ value: 1, label: "Rumah" }, { value: 2, label: "" }, { value: 3, label: "" }, { value: 4, label: "" }, { value: 5, label: "Bisnis" }];
    const surveyOptions7 = [{ value: 1, label: "Low Technology" }, { value: 2, label: "" }, { value: 3, label: "" }, { value: 4, label: "" }, { value: 5, label: "High Technology" }];

    return (
      <React.Fragment>
        <Row className={`pt-20`}>
          <Col xxl={{ span: 16, offset: 4 }} xl={{ span: 16, offset: 4 }} lg={{ span: 20, offset: 2 }} md={{ span: 20, offset: 2 }} sm={{ span: 24 }} xs={{ span: 24 }}>
            <Breadcrumb className={`pl-20`}>
              <Breadcrumb.Item>AmartaChery</Breadcrumb.Item>
              <Breadcrumb.Item>Survey</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>

        <div style={this.state.fetching ? { textAlign: "center", paddingTop: "350px", paddingBottom: "400px" } : { display: "none" }}>
          <Spin style={{ marginTop: 20 }} size={"large"} />
        </div>

        <div style={this.state.complete ? { textAlign: "center", paddingTop: "250px", paddingBottom: "200px" } : { display: "none" }}>
          <Result
            status="success"
            title="Pengisian Survey Sukses"
            subTitle="Terimakasih banyak atas waktu dan partisipasi Anda dalam mengisi survey kami. Kontribusi Anda sangat berarti bagi pengembangan kami."
          />
        </div>

        <div style={(!this.state.fetching && !this.state.complete) ? {} : { display: "none" }}>
          <Row className={`mt-20`} style={{ backgroundColor: "#f8f8f8" }}>
            <Col xxl={{ span: 16, offset: 4 }} xl={{ span: 16, offset: 4 }} lg={{ span: 20, offset: 2 }} md={{ span: 20, offset: 2 }} sm={{ span: 24 }} xs={{ span: 24 }}>
              <Row>
                <Col className={`p-20`} xxl={{ span: 16 }} xl={{ span: 16 }} md={{ span: 16 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                  <div className={`detail-product-title`}>Survey AmartaChery</div>
                  <br />
                  <div className={`detail-product-caption`}>
                    Terima Kasih atas kesediannya untuk berpartisipasi dalam riset kami. Kami ingin menyampaikan beberapa pertanyaan kepada anda, yang dapat membantu kami untuk meningkatkan produk dan pelayanan kami.<br /><br />
                    Saran dan ide anda akan sangat membantu perkembangan produk kami di masa depan.
                  </div>
                </Col>
                <Col className={`p-20`} xxl={{ span: 8 }} xl={{ span: 8 }} md={{ span: 8 }} sm={{ span: 0 }} xs={{ span: 0 }}>
                  <img src={`https://trimitra-cdn.s3.ap-southeast-1.amazonaws.com/assets/image/profiling.png`} style={{ height: 180 }} alt={`logo`} />
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className={`p-20 mt-10`}>
            <Col xxl={{ span: 14, offset: 5 }} xl={{ span: 16, offset: 4 }} lg={{ span: 20, offset: 2 }} md={{ span: 20, offset: 2 }} sm={{ span: 24 }} xs={{ span: 24 }}>
              <Row>
                <Col className={`p-10`} xxl={{ span: 10 }} xl={{ span: 10 }} lg={{ span: 10 }} md={{ span: 24 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                  <div className={`pt-10 detail-product-title`}>{this.state.dataVehicleName.toLowerCase().replace(/\b\w/g, (s: string) => s.toUpperCase())}</div>
                  {this.state.dataVehicleSpec.map((item: any, i: number) =>
                    <div className={`pt-10 card-promo-caption`}>{item}</div>
                  )}
                </Col>
                <Col className={`p-10`} xxl={{ span: 14 }} xl={{ span: 14 }} lg={{ span: 14 }} md={{ span: 24 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                  <Row>
                    {this.state.dataVehicleImage.map((item: any, i: number) =>
                      <Col span={6} style={{ padding: 5 }}>
                        <Image
                          src="error"
                          fallback={item}
                        />
                      </Col>
                    )}
                  </Row>
                </Col>
              </Row>

              <Divider className={`pt-20`} orientation="left">Pengalaman Setelah TestDrive</Divider>
              <Row>
                <Col className={`p-10`} xxl={{ span: 12 }} xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 24 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                  <Form.Item>
                    <i className={`text-small-grey-nopadding`}>Pengalaman anda setelah melakukan testDrive</i>
                    <TextArea
                      rows={3} value={this.state.testDriveResult}
                      onChange={event => this.onFieldChange("testDriveResult", event.target.value)}
                      placeholder="Deskripsi pengalaman anda setelah melakukan testDrive"
                    />
                  </Form.Item>
                </Col>
                <Col className={`p-10`} xxl={{ span: 12 }} xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 24 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                  <Form.Item>
                    <i className={`text-small-grey-nopadding`}>Setelah melakukan testDrive, apakah anda ingin merekomendasikan kendaraan ini?</i><br />
                    <Radio.Group onChange={(event) => this.onFieldChange("surveyRecommended", event.target.value)} value={this.state.surveyRecommended}>
                      <Space direction="vertical">
                        <Radio value={"impossible"}>Tidak Mungkin</Radio>
                        <Radio value={"not_sure"}>Tidak Yakin</Radio>
                        <Radio value={"maybe"}>Mungkin</Radio>
                        <Radio value={"recommend"}>Sangat Mungkin Merekomendasikan</Radio>
                      </Space>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>

              <Divider orientation="left">Evaluasi Kendaraan</Divider>
              <Row className={`mt-20`}>
                <Col className={`p-10`} xxl={{ span: 12 }} xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 24 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                  <Divider orientation="left" plain>Interior</Divider>
                  <Form.Item>
                    <i className={`text-small-grey-nopadding`}>Hal yang anda suka dari Interior kendaraan</i>
                    <TextArea
                      rows={3} value={this.state.interiorSatisfied}
                      onChange={event => this.onFieldChange("interiorSatisfied", event.target.value)}
                      placeholder="Deskripsi Kepuasan anda terhadap Interior kendaraan"
                    />
                  </Form.Item>
                  <Form.Item>
                    <i className={`text-small-grey-nopadding`}>Hal yang anda tidak suka dari Interior kendaraan</i>
                    <TextArea
                      rows={3} value={this.state.interiorDissatisfied}
                      onChange={event => this.onFieldChange("interiorDissatisfied", event.target.value)}
                      placeholder="Bagian mana pada Interior yang membuat anda tidak puas / tidak suka."
                    />
                  </Form.Item>
                  <Divider orientation="left" plain>Exterior</Divider>
                  <Form.Item>
                    <i className={`text-small-grey-nopadding`}>Hal yang anda suka dari Exterior kendaraan</i>
                    <TextArea
                      rows={3} value={this.state.exteriorSatisfied}
                      onChange={event => this.onFieldChange("exteriorSatisfied", event.target.value)}
                      placeholder="Deskripsi Kepuasan anda terhadap Exterior kendaraan"
                    />
                  </Form.Item>
                  <Form.Item>
                    <i className={`text-small-grey-nopadding`}>Hal yang anda tidak suka dari Exterior kendaraan</i>
                    <TextArea
                      showCount maxLength={500} rows={3} value={this.state.exteriorDissatisfied}
                      onChange={event => this.onFieldChange("exteriorDissatisfied", event.target.value)}
                      placeholder="Bagian mana pada Exterior yang membuat anda tidak puas / tidak suka."
                    />
                  </Form.Item>
                </Col>
                <Col className={`p-10`} xxl={{ span: 12 }} xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 24 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                  <Divider orientation="left" plain>Survey Keseluruhan</Divider>
                  <table style={{ width: "100%" }}>
                    <thead>
                    <tr style={{ backgroundColor: "#e1e1e1", padding: 10 }}>
                      {surveyOptions.map((option) => (
                        <td style={{ width: "20%", textAlign: "center", padding: 10 }}>{option.value}</td>
                      ))}
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                      {surveyOptions.map((option) => (
                        <td style={{ paddingTop: 5, paddingBottom: 5 }} key={option.value}>
                          <div className={`text-small-grey-nopadding`} style={{ textAlign: "center", paddingBottom: (option.label) ? 5 : 20 }}>{option.label}</div>
                          <div style={{ textAlign: "center" }}>
                            <Radio.Group onChange={(event) => this.onFieldChange("surveyLuxury", event.target.value)} value={this.state.surveyLuxury}>
                              <Radio value={option.value} />
                            </Radio.Group>
                          </div>
                        </td>
                      ))}
                    </tr>
                    <tr>
                      {surveyOptions2.map((option) => (
                        <td style={{ paddingTop: 5, paddingBottom: 5 }} key={option.value}>
                          <div className={`text-small-grey-nopadding`} style={{ textAlign: "center", paddingBottom: (option.label) ? 5 : 20 }}>{option.label}</div>
                          <div style={{ textAlign: "center" }}>
                            <Radio.Group onChange={(event) => this.onFieldChange("surveyFeminine", event.target.value)} value={this.state.surveyFeminine}>
                              <Radio value={option.value} />
                            </Radio.Group>
                          </div>
                        </td>
                      ))}
                    </tr>
                    <tr>
                      {surveyOptions3.map((option) => (
                        <td style={{ paddingTop: 5, paddingBottom: 5 }} key={option.value}>
                          <div className={`text-small-grey-nopadding`} style={{ textAlign: "center", paddingBottom: (option.label) ? 5 : 20 }}>{option.label}</div>
                          <div style={{ textAlign: "center" }}>
                            <Radio.Group onChange={(event) => this.onFieldChange("surveyUserAge", event.target.value)} value={this.state.surveyUserAge}>
                              <Radio value={option.value} />
                            </Radio.Group>
                          </div>
                        </td>
                      ))}
                    </tr>
                    <tr>
                      {surveyOptions5.map((option) => (
                        <td style={{ paddingTop: 5, paddingBottom: 5 }} key={option.value}>
                          <div className={`text-small-grey-nopadding`} style={{ textAlign: "center", paddingBottom: (option.label) ? 5 : 20 }}>{option.label}</div>
                          <div style={{ textAlign: "center" }}>
                            <Radio.Group onChange={(event) => this.onFieldChange("surveyRoadField", event.target.value)} value={this.state.surveyRoadField}>
                              <Radio value={option.value} />
                            </Radio.Group>
                          </div>
                        </td>
                      ))}
                    </tr>
                    <tr>
                      {surveyOptions6.map((option) => (
                        <td style={{ paddingTop: 5, paddingBottom: 5 }} key={option.value}>
                          <div className={`text-small-grey-nopadding`} style={{ textAlign: "center", paddingBottom: (option.label) ? 5 : 20 }}>{option.label}</div>
                          <div style={{ textAlign: "center" }}>
                            <Radio.Group onChange={(event) => this.onFieldChange("surveyBusinessUsage", event.target.value)} value={this.state.surveyBusinessUsage}>
                              <Radio value={option.value} />
                            </Radio.Group>
                          </div>
                        </td>
                      ))}
                    </tr>
                    <tr>
                      {surveyOptions7.map((option) => (
                        <td style={{ paddingTop: 5, paddingBottom: 5 }} key={option.value}>
                          <div className={`text-small-grey-nopadding`} style={{ textAlign: "center", paddingBottom: (option.label) ? 5 : 20 }}>{option.label}</div>
                          <div style={{ textAlign: "center" }}>
                            <Radio.Group onChange={(event) => this.onFieldChange("surveyTechnology", event.target.value)} value={this.state.surveyTechnology}>
                              <Radio value={option.value} />
                            </Radio.Group>
                          </div>
                        </td>
                      ))}
                    </tr>
                    </tbody>
                  </table>
                </Col>
              </Row>

              <Divider orientation="left">Karakteristik Calon Konsumen</Divider>
              <Row className={`mt-20`}>
                <Col className={`p-10`} xxl={{ span: 12 }} xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 24 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                  <Form.Item>
                    <i className={`text-small-grey-nopadding`}>Faktor apa yang paling anda pertimbangkan sewaktu membeli kendaraan? <br />(tentukan minimal 1 opsi / maksimal 5 opsi yang paling anda prioritaskan)</i><br />
                    <Row>
                      <Col span={12}>
                        <Checkbox className={`pt-10`} checked={this.state.checkBoxInterior} onChange={e => this.onFieldChange("checkBoxInterior", e.target.checked, "countFactor")}>
                          <span style={{ fontSize: "80%" }}>Desain Interior</span>
                        </Checkbox><br />
                        <Checkbox className={`pt-10`} checked={this.state.checkBoxExterior} onChange={e => this.onFieldChange("checkBoxExterior", e.target.checked, "countFactor")}>
                          <span style={{ fontSize: "80%" }}>Desain Exterior</span>
                        </Checkbox><br />
                        <Checkbox className={`pt-10`} checked={this.state.checkBoxFeature} onChange={e => this.onFieldChange("checkBoxFeature", e.target.checked, "countFactor")}>
                          <span style={{ fontSize: "80%" }}>Fitur</span>
                        </Checkbox><br />
                        <Checkbox className={`pt-10`} checked={this.state.checkBoxBrand} onChange={e => this.onFieldChange("checkBoxBrand", e.target.checked, "countFactor")}>
                          <span style={{ fontSize: "80%" }}>Merek</span>
                        </Checkbox><br />
                        <Checkbox className={`pt-10`} checked={this.state.checkBoxFuelConsumption} onChange={e => this.onFieldChange("checkBoxFuelConsumption", e.target.checked, "countFactor")}>
                          <span style={{ fontSize: "80%" }}>Konsumsi Bahan Bakar</span>
                        </Checkbox><br />
                        <Checkbox className={`pt-10`} checked={this.state.checkBoxPrice} onChange={e => this.onFieldChange("checkBoxPrice", e.target.checked, "countFactor")}>
                          <span style={{ fontSize: "80%" }}>Harga</span>
                        </Checkbox><br />
                        <Checkbox className={`pt-10`} checked={this.state.checkBoxComfort} onChange={e => this.onFieldChange("checkBoxComfort", e.target.checked, "countFactor")}>
                          <span style={{ fontSize: "80%" }}>Kenyamanan</span>
                        </Checkbox><br />
                        <Checkbox className={`pt-10`} checked={this.state.checkBoxOther} onChange={e => this.onFieldChange("checkBoxOther", e.target.checked, "countFactor")}>
                          <span style={{ fontSize: "80%" }}>Lainya</span>
                        </Checkbox><br />
                        <Input style={this.state.checkBoxOther ? {} : { display: "none" }} className={`pt-10`} value={this.state.checkBoxOtherText} onChange={event => this.onFieldChange("checkBoxOtherText", event.target.value)} placeholder="Faktor Lain" />
                      </Col>
                      <Col span={12}>
                        <Checkbox className={`pt-10`} checked={this.state.checkBoxPerform} onChange={e => this.onFieldChange("checkBoxPerform", e.target.checked, "countFactor")}>
                          <span style={{ fontSize: "80%" }}>Daya & Performa</span>
                        </Checkbox><br />
                        <Checkbox className={`pt-10`} checked={this.state.checkBoxDurable} onChange={e => this.onFieldChange("checkBoxDurable", e.target.checked, "countFactor")}>
                          <span style={{ fontSize: "80%" }}>Ketahanan & Kehandalan</span>
                        </Checkbox><br />
                        <Checkbox className={`pt-10`} checked={this.state.checkBoxAfterSales} onChange={e => this.onFieldChange("checkBoxAfterSales", e.target.checked, "countFactor")}>
                          <span style={{ fontSize: "80%" }}>Layanan After Sales</span>
                        </Checkbox><br />
                        <Checkbox className={`pt-10`} checked={this.state.checkBoxCabinRelief} onChange={e => this.onFieldChange("checkBoxCabinRelief", e.target.checked, "countFactor")}>
                          <span style={{ fontSize: "80%" }}>Kelegaan Kabin</span>
                        </Checkbox><br />
                        <Checkbox className={`pt-10`} checked={this.state.checkBoxSparePart} onChange={e => this.onFieldChange("checkBoxSparePart", e.target.checked, "countFactor")}>
                          <span style={{ fontSize: "80%" }}>Spare Part</span>
                        </Checkbox><br />
                        <Checkbox className={`pt-10`} checked={this.state.checkBoxTechnology} onChange={e => this.onFieldChange("checkBoxTechnology", e.target.checked, "countFactor")}>
                          <span style={{ fontSize: "80%" }}>Teknologi</span>
                        </Checkbox><br />
                        <Checkbox className={`pt-10`} checked={this.state.checkBoxSecurity} onChange={e => this.onFieldChange("checkBoxSecurity", e.target.checked, "countFactor")}>
                          <span style={{ fontSize: "80%" }}>Keamanan</span>
                        </Checkbox><br />
                      </Col>
                    </Row>
                  </Form.Item>

                  <Form.Item>
                    <i className={`text-small-grey-nopadding`}>Dari mana anda mendapatkan informasi yang berhubungan dengan kendaraan?</i><br />
                    <Row>
                      <Col span={12}>
                        <Checkbox className={`pt-10`} checked={this.state.checkBoxInfoTv} onChange={e => this.onFieldChange("checkBoxInfoTv", e.target.checked, "checkInfo")}>
                          <span style={{ fontSize: "80%" }}>TV</span>
                        </Checkbox><br />
                        <Checkbox className={`pt-10`} checked={this.state.checkBoxInfoShoppingMall} onChange={e => this.onFieldChange("checkBoxInfoShoppingMall", e.target.checked, "checkInfo")}>
                          <span style={{ fontSize: "80%" }}>Shopping Mall</span>
                        </Checkbox><br />
                        <Checkbox className={`pt-10`} checked={this.state.checkBoxInfoOfficialWebsite} onChange={e => this.onFieldChange("checkBoxInfoOfficialWebsite", e.target.checked, "checkInfo")}>
                          <span style={{ fontSize: "80%" }}>Website Official</span>
                        </Checkbox><br />
                        <Checkbox className={`pt-10`} checked={this.state.checkBoxInfoInternet} onChange={e => this.onFieldChange("checkBoxInfoInternet", e.target.checked, "checkInfo")}>
                          <span style={{ fontSize: "80%" }}>Internet</span>
                        </Checkbox><br />
                        <Checkbox className={`pt-10`} checked={this.state.checkBoxInfoPhone} onChange={e => this.onFieldChange("checkBoxInfoPhone", e.target.checked, "checkInfo")}>
                          <span style={{ fontSize: "80%" }}>Ponsel (Pesan Whatsapp, SMS, dll)</span>
                        </Checkbox><br />
                        <Checkbox className={`pt-10`} checked={this.state.checkBoxInfoMagazine} onChange={e => this.onFieldChange("checkBoxInfoMagazine", e.target.checked, "checkInfo")}>
                          <span style={{ fontSize: "80%" }}>Majalah</span>
                        </Checkbox><br />
                        <Checkbox className={`pt-10`} checked={this.state.checkBoxInfoOther} onChange={e => this.onFieldChange("checkBoxInfoOther", e.target.checked, "checkInfo")}>
                          <span style={{ fontSize: "80%" }}>Lainya</span>
                        </Checkbox><br />
                        <Input style={this.state.checkBoxInfoOther ? {} : { display: "none" }} className={`pt-10`} value={this.state.checkBoxInfoOtherText} onChange={event => this.onFieldChange("checkBoxInfoOtherText", event.target.value)} placeholder="Sumber Lain" />
                      </Col>
                      <Col span={12}>
                        <Checkbox className={`pt-10`} checked={this.state.checkBoxInfoSocialMedia} onChange={e => this.onFieldChange("checkBoxInfoSocialMedia", e.target.checked, "checkInfo")}>
                          <span style={{ fontSize: "80%" }}>Media Sosial (Youtube, Facebook dll)</span>
                        </Checkbox><br />
                        <Checkbox className={`pt-10`} checked={this.state.checkBoxInfoExhibition} onChange={e => this.onFieldChange("checkBoxInfoExhibition", e.target.checked, "checkInfo")}>
                          <span style={{ fontSize: "80%" }}>Pameran</span>
                        </Checkbox><br />
                        <Checkbox className={`pt-10`} checked={this.state.checkBoxInfoBillboard} onChange={e => this.onFieldChange("checkBoxInfoBillboard", e.target.checked, "checkInfo")}>
                          <span style={{ fontSize: "80%" }}>Papan Reklame</span>
                        </Checkbox><br />
                        <Checkbox className={`pt-10`} checked={this.state.checkBoxInfoRelatives} onChange={e => this.onFieldChange("checkBoxInfoRelatives", e.target.checked, "checkInfo")}>
                          <span style={{ fontSize: "80%" }}>Keluarga / Kerabat</span>
                        </Checkbox><br />
                        <Checkbox className={`pt-10`} checked={this.state.checkBoxInfoClub} onChange={e => this.onFieldChange("checkBoxInfoClub", e.target.checked, "checkInfo")}>
                          <span style={{ fontSize: "80%" }}>Klub Kendaraan</span>
                        </Checkbox><br />
                        <Checkbox className={`pt-10`} checked={this.state.checkBoxInfoDealer} onChange={e => this.onFieldChange("checkBoxInfoDealer", e.target.checked, "checkInfo")}>
                          <span style={{ fontSize: "80%" }}>Dealer Lokal</span>
                        </Checkbox><br />
                      </Col>
                    </Row>
                  </Form.Item>
                </Col>
                <Col className={`p-10`} xxl={{ span: 12 }} xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 24 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                  <Form.Item>
                    <i className={`text-small-grey-nopadding`}>Penggunaan Kendaraan bagi calon konsumen?</i><br />
                    <Radio.Group onChange={(event) => this.onFieldChange("vehicleUsage", event.target.value)} value={this.state.vehicleUsage}>
                      <Space direction="vertical">
                        <Radio value={"first_vehicle"}>Sebagai Kendaraan Pertama</Radio>
                        <Radio value={"replace_existing_vehicle"}>Menggantikan kendaraan yang sudah ada</Radio>
                        <Radio value={"increase_ownership_vehicle"}>Menambah kepemilikan kendaraan</Radio>
                      </Space>
                    </Radio.Group>
                  </Form.Item>
                  <Form.Item>
                    <i className={`text-small-grey-nopadding`}>Rencana pembelian kendaraan?</i><br />
                    <Radio.Group onChange={(event) => this.onFieldChange("purchasePlan", event.target.value)} value={this.state.purchasePlan}>
                      <Space direction="vertical">
                        <Radio value={"1_month"}>1 Bulan Kedepan</Radio>
                        <Radio value={"2_months"}>2 Bulan Kedepan</Radio>
                        <Radio value={"3_months"}>3 Bulan Kedepan</Radio>
                        <Radio value={"more_than_3_months"}>{">"} 3 Bulan Kedepan</Radio>
                      </Space>
                    </Radio.Group>
                  </Form.Item>
                  <Form.Item>
                    <i className={`text-small-grey-nopadding`}>Rencana metode pembelian?</i><br />
                    <Radio.Group onChange={(event) => this.onFieldChange("purchaseMethod", event.target.value)} value={this.state.purchaseMethod}>
                      <Radio value={"cash"}>Tunai</Radio>
                      <Radio value={"credit"}>Kredit</Radio>
                    </Radio.Group>
                  </Form.Item>
                  <Card title="Skema Kredit" size={"small"} style={this.state.purchaseMethod === "credit" ? { width: "100%" } : { display: "none" }}>
                    <Form.Item>
                      <i className={`text-small-grey-nopadding`}>Uang Muka</i> <i style={{ color: "gray", fontSize: "70%" }}>(Kelipatan 1jt)</i>
                      <CurrencyInput
                        className={"ant-input same-ant-input"}
                        groupSeparator={"."}
                        decimalSeparator={","}
                        prefix={"Rp. "}
                        style={{ marginTop: 5, width: "97%" }}
                        value={this.state.downPayment}
                        onValueChange={value => this.onFieldChange("downPayment", value ? parseFloat(value) : 0)}
                        onBlur={this.blurDownPayment}
                      />
                    </Form.Item>
                    <Form.Item>
                      <i className={`text-small-grey-nopadding`}>Tenor</i><br />
                      <CurrencyInput
                        className={"ant-input same-ant-input"}
                        max={100}
                        style={{ marginTop: 5, width: "97%" }}
                        value={this.state.tenor}
                        onValueChange={value => this.onFieldChange("tenor", value ? parseFloat(value) : 0)}
                        onBlur={this.blurTenor}
                      />
                    </Form.Item>
                    <Form.Item>
                      <i className={`text-small-grey-nopadding`}>Cicilan</i> <i style={{ color: "gray", fontSize: "70%" }}>(Kelipatan 100k)</i>
                      <CurrencyInput
                        className={"ant-input same-ant-input"}
                        groupSeparator={"."}
                        decimalSeparator={","}
                        prefix={"Rp. "}
                        style={{ marginTop: 5, width: "97%" }}
                        value={this.state.installmentAmount}
                        onValueChange={value => this.onFieldChange("installmentAmount", value ? parseFloat(value) : 0)}
                        onBlur={this.blurInstallmentAmount}
                      />
                    </Form.Item>
                    <Row>
                      <Col span={24}>
                        <br /><i style={{ fontSize: "80%" }}>*Angsuran bisa berubah sesuai anjuran finance company / leasing selama belum tanda tangan perjanjian kredit</i><br />
                      </Col>
                    </Row>
                  </Card><br />
                </Col>
              </Row>
              <Button onClick={() => this.savingData()} loading={this.state.saving} className={`mt-20`} type="primary">Kirim Survey</Button>
            </Col>
          </Row>
        </div>

        <div style={{ minHeight: 200 }} />
        <FooterSection />
      </React.Fragment>
    );
  }
}

export default withRouter(ProductSurvey);
