import React, { Component } from 'react';
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import { Header } from "antd/es/layout/layout";

import Cookies from "universal-cookie";

class MyProfileLoginHeader extends Component<any> {
    private cookies = new Cookies();

    logout = async () => {
        const c = this.cookies.get('_tc-t');
        if (c) {
            await this.cookies.remove('_tc-t');
            await this.cookies.remove('_tc-n');
            await this.cookies.remove('_tc-p');
            await this.cookies.remove('_tc-e');
            await this.cookies.remove('_tc-ut');
            await this.cookies.remove('_tc-uc');
            await this.cookies.remove('_tc-ui');
        }

        window.location.href = "/login";
    }

    render() {

        return (
            <Header className={`desktop-header-search`} style={{ backgroundColor: '#fff' }}>
                <Row>
                    <Col span={16}>
                        <Row>
                            <strong style={{ fontSize: 16 }}>
                                CRM AMARTA VIP
                            </strong>
                        </Row>
                    </Col>
                    <Col span={8} style={{ textAlign: "right" }}>
                        <div style={{ fontSize: "100%", color: "#4b4b4b", fontWeight: 500, marginTop: -2, textAlign: "right" }}>{this.props.title?.toLowerCase().replace(/\b\w/g, (s: string) => s.toUpperCase())}</div>
                    </Col>
                </Row>
            </Header>
        );
    }
}

export default MyProfileLoginHeader;
