import React from "react";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

import { Row, Col } from "antd";

dayjs.extend(customParseFormat);
const dateFormat = "YYYY-MM-DD HH:ss";

interface ListAppointmentProps {
  data: any[];
}

const ListAppointment: React.FC<ListAppointmentProps> = ({ data }) => {
  console.log("ajdhadhghhj", data[0]);

  const dataOwner = data[0];
  return (
    <center>
      <Row justify="center">
        <Col xl={{ span: 8 }} lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }} xs={{ span: 24 }} style={{ padding: 10 }}>
          <div>
            <h3>Jadwal Service Kendaraan</h3>
          </div>
          <div>
            <table className="table-no-border ">
              <tbody>
                <tr>
                  <td>Atas&nbsp;Nama</td>
                  <td>:</td>
                  <td>{dataOwner?.client_name}</td>
                </tr>
                <tr>
                  <td>No&nbsp;Telp</td>
                  <td>:</td>
                  <td>{dataOwner?.client_phone_number}</td>
                </tr>
                <tr>
                  <td>Kendaraan</td>
                  <td>:</td>
                  <td>
                    {dataOwner?.vehicle_model} - {dataOwner?.vehicle_license_plate}
                  </td>
                </tr>
                <tr>
                  <td>Lokasi</td>
                  <td>:</td>
                  <td>
                    {dataOwner?.vendor_name} - {dataOwner?.vendor_code}
                  </td>
                </tr>
              </tbody>
            </table>
            <br />
            <table className="table-bordered" style={{ height: "100%", width: "100%", margin: "auto" }}>
              <tbody>
                <tr>
                  <th>Kode&nbsp;Service</th>
                  <th>Tipe&nbsp;</th>
                  <th>Waktu&nbsp;Service</th>
                </tr>
                {data.map((appo) => (
                  <tr>
                    <td>{appo?.code}</td>
                    <td>{appo?.appointment_type}</td>
                    <td>{dayjs(appo?.appointment_time).format(dateFormat)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Col>
      </Row>
    </center>
  );
};

export default ListAppointment;
