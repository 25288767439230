import axios from "axios";
import { baseUrlBookingAmartaVip } from "../../config/apiConfig/apiConfig";

class BookingAmartaVipServices {
  private axios = axios.create({
    baseURL: baseUrlBookingAmartaVip,
  });

  public async postBookingService(post_data: any) {
    try {
      return await this.axios.post<any>("/appointment", post_data);
    } catch (e: any) {
      throw new Error(e);
    }
  }

  public async getBookingService(params: any) {
    try {
      const queries = {
        ...(params?.license_plate && {
          license_plate: params.license_plate,
        }),
      };

      const data = await this.axios.get("appointment", {
        params: {
          ...queries,
        },
      });
      return data.data;
    } catch (e: any) {
      return e.response.data;
    }
  }
}

export const bookingAmartaVipServices = new BookingAmartaVipServices();
