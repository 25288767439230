import React, { Component } from "react";
import Select from "antd/lib/select";
import { vehicleServices } from "../../services/autotrimitra/VehicleServices";

class SelectVehicleVariant extends Component<any, any> {
    private readonly initState!: any;

    constructor(props: any) {
        super(props);

        this.initState = {
            dataVehicleVariant: [],
            vehicleVariantUuid: null,
            vehicleVariantCode: null,
            vehicleVariantName: null,
        }
        this.state = { ...this.initState }
    }

    async componentDidMount() {
        await this.fetchVehicleVariantData(this.props.vehicleModelUuid);
        await this.onValueChange(this.props.value);
    }

    onValueChange = (value: any) => {
        this.setState({
            vehicleVariantUuid: value,
        });
    }

    onChange = async (value: any, option: any) => {

        this.setState({
            vehicleVariantUuid: value,
            vehicleVariantCode: option.code,
            vehicleVariantName: option.children,
        });
        this.props.onVehicleVariantChangeProps(option);
    }

    fetchVehicleVariantData = (vehicleModelUuid: string) => {
        if (vehicleModelUuid) {
            // FETCH API
            try {
                vehicleServices.getVariantlList({
                    model_uuid: vehicleModelUuid
                }).then(dataFetched => {
                    let listOption = dataFetched.data.data.map((d: { variant_uuid: string, variant_name: string, code: string }) => {
                        return { value: d.variant_uuid, name: d.variant_name, code: d.code };
                    });

                    this.setState({
                        dataVehicleVariant: listOption,
                    });
                });
            } catch (e) {
                this.setState({
                    dataVehicleVariant: [],
                    vehicleVariantUuid: null,
                    vehicleVariantCode: null,
                    vehicleVariantName: null,
                });
            }
        } else {
            this.setState({
                dataVehicleVariant: [],
                vehicleVariantUuid: null,
                vehicleVariantCode: null,
                vehicleVariantName: null,
            });
        }
    }

    async componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any) {
        if (this.props.vehicleModelUuid !== prevProps.vehicleModelUuid) {
            await this.fetchVehicleVariantData(this.props.vehicleModelUuid);
        }
        if (this.props.value !== prevProps.value) {
            this.onValueChange(this.props.value);
        }
    }

    render() {
        return (
            <Select
                showSearch
                placeholder="Pilih Variant Kendaraan"
                optionFilterProp="children"
                onChange={this.onChange}
                style={{ width: "100%" }}
                filterOption={(input: any, option: any) =>
                    option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                value={this.state.vehicleVariantUuid}
            >
                {
                    this.state.dataVehicleVariant.map((item: any, i: number) =>
                        <Select.Option key={item?.value} value={item?.value?.toLowerCase()} code={item.code}>{item?.name}</Select.Option>
                    )
                }
            </Select>
        );
    }
}

export default SelectVehicleVariant;
