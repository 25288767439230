import React, { Component } from "react";
import { withRouter } from "../../hoc/withRouter";
import dayjs from "dayjs";

import customParseFormat from "dayjs/plugin/customParseFormat";

import { invoiceItemsStates } from "./types/invoiceItemsTypes";
import { workorderServices } from "../../services/bengkel/WorkorderServices";
import { Col, Row } from "antd";

dayjs.extend(customParseFormat);

export interface invoiceItemsProps {}

class invoiceItems extends Component<invoiceItemsProps & any, invoiceItemsStates> {
  constructor(props: invoiceItemsProps & any) {
    super(props);

    this.state = {
      submitting: false,
      freezeStatus: false,
      processing: false,

      dataServices: {},
      serviceCode: this.props.match.params.service_code,
      workDuration: 0,
      workDurationType: "JAM",
    };
  }

  async componentDidMount() {
    workorderServices
      .getWorkorderList(this.state.serviceCode)
      .then((dataFetchClient) => {
        const dataClient = dataFetchClient.data.success.data;

        if (dataClient?.length > 0) {
          const date1 = dayjs(dataClient[0].event_created);
          const date2 = dayjs(dataClient[0].event_completed);

          let work_duration = date2.diff(date1, "hour");
          let work_duration_type = "JAM";

          if (work_duration < 1) {
            work_duration = date2.diff(date1, "minute");
            work_duration_type = "Menit";
          }

          this.setState({
            dataServices: dataClient[0],
            workDuration: work_duration,
            workDurationType: work_duration_type,
          });
        } else {
          this.setState({
            dataServices: {},
          });
        }
      })
      .catch((e) => {
        this.setState({
          dataServices: {},
        });
      });
  }

  render() {
    const { dataServices } = this.state;
    let total_all = 0;

    return (
      <React.Fragment>
        <center>
          <Row justify="center">
            <Col xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }} xs={{ span: 24 }} style={{ padding: 10 }}>
              <div className="py-2 col-lg-6">
                <div className="row">
                  <div className="col-12 text-center">
                    <h3>NOTA&nbsp;PENJUALAN&nbsp;SUKU&nbsp;CADANG </h3>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <table className="table-bordered m-1 shadow-sm col-12">
                      <tbody>
                        <tr>
                          <td className="p-1" colSpan={2}>
                            Nomor WO&nbsp;:&nbsp;{dataServices?.workorder_code}
                          </td>
                        </tr>
                        <tr>
                          <td className="p-1">
                            Nomor Polisi&nbsp;:&nbsp;
                            {dataServices?.vehicle_license_plate}
                          </td>
                          <td className="p-1">Kilometer&nbsp;:&nbsp;{dataServices?.travel_distance}</td>
                        </tr>
                        <tr>
                          <td className="p-1">
                            Tipe/Warna/Tahun &nbsp;:&nbsp;
                            {dataServices?.vehicle_variant_name}/{dataServices?.vehicle_color_name}/{dataServices?.vehicle_year}
                          </td>
                          <td className="p-1">Telepon&nbsp;:&nbsp;{dataServices?.customer_phone}</td>
                        </tr>
                        <tr>
                          <td className="p-1">Nama Pemilik&nbsp;:&nbsp;{dataServices?.customer_name}</td>
                          <td className="p-1">Waktu Mulai WO&nbsp;:&nbsp;{dataServices?.event_created}</td>
                        </tr>
                        <tr>
                          <td className="p-1">Nama Bengkel&nbsp;:&nbsp;{dataServices?.workshop_name}</td>
                          <td className="p-1">
                            Waktu Selesai WO&nbsp;:&nbsp;
                            {dataServices?.event_completed}
                          </td>
                        </tr>
                        <tr>
                          <td className="p-1">Nama Mekanik&nbsp;:&nbsp;{dataServices?.mechanic_name}</td>
                          <td className="p-1">
                            Waktu Pengerjaan&nbsp;:&nbsp;
                            {this.state.workDuration} Menit
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={2}>
                            <table className="table-bordered" style={{ height: "100%", width: "90%", margin: "auto" }}>
                              <tbody>
                                <tr>
                                  <th className="p-1">Kode&nbsp;Brg</th>
                                  <th className="p-1">Nama</th>
                                  <th className="p-1">Qty</th>
                                  <th className="p-1">Hrg&nbsp;Sat</th>
                                  <th className="p-1">Hrg&nbsp;Gros</th>
                                  <th className="p-1">Disc</th>
                                  <th className="p-1">Hrg&nbsp;Net</th>
                                </tr>
                                {dataServices?.parts?.map((d: any, i: number) => {
                                  const total_gross = d?.quantity * d?.price;
                                  let discount = 0;
                                  if (d.discount_type === "%") {
                                    discount = (total_gross * d?.discount) / 100;
                                  } else if (d.discount_type === "FLAT") {
                                    discount = d?.discount;
                                  }
                                  const total_net = total_gross - discount;
                                  total_all += total_net;

                                  return (
                                    <tr key={d?.part_code}>
                                      <td className="p-1">{d?.part_code}</td>
                                      <td className="p-1">{d?.part_name}</td>
                                      <td className="p-1">{d?.quantity}</td>
                                      <td className="p-1">{d?.price}</td>
                                      <td className="p-1">{total_gross}</td>
                                      <td className="p-1">{discount}</td>
                                      <td className="p-1">{total_net}</td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </td>
                        </tr>
                        <tr>
                          <td className="p-1">
                            Status Pembayaran&nbsp;:&nbsp;
                            {dataServices?.payment_status}
                          </td>
                          <td className="p-1">Total&nbsp;:&nbsp;{total_all}</td>
                        </tr>
                        <tr>
                          <td colSpan={2} style={{ paddingLeft: "50px" }}>
                            <ul>
                              <li>Berlaku sebagai faktur pajak sederhana</li>
                              <li>Garansi servis berlaku 7 hari</li>
                              <li>Kami tidak bertanggung jawab atas hilang atau terbuangnya suku cadang BEKAS bila tidak diambil dalam waktu 2 hari dari waktu selesai</li>
                              <li>Tautan eNota akan kadaluarsa 7 hari dari tanggal selesai</li>
                            </ul>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </center>
      </React.Fragment>
    );
  }
}

export default withRouter(invoiceItems);
