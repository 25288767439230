/** PRODUCTION LINK */
const baseUrlTrimitraCatalog = "https://zvu1c5uoue.execute-api.ap-southeast-1.amazonaws.com/v1";
const baseUrlAmartaVip = "https://3krjkubmjk.execute-api.ap-southeast-3.amazonaws.com/v1";
const baseUrlCdnUpload = "https://hbxhwzeej9.execute-api.ap-southeast-1.amazonaws.com/v1";
const baseUrlAutotrimitra = "https://au-api-trimitra-get-65q45htc.ts.gateway.dev/";
const baseUrlOtodis = "https://c1dbgj7gv9.execute-api.ap-southeast-1.amazonaws.com/otodis";
const baseUrlCrmAmartaVip = "https://3krjkubmjk.execute-api.ap-southeast-3.amazonaws.com/v1";
const baseUrlBookingAmartaVip = "https://nc048fl7l8.execute-api.ap-southeast-1.amazonaws.com/v1";
const baseurlBengkel = "https://g187tdl36e.execute-api.ap-southeast-3.amazonaws.com/v1";
const baseurlIdeal = "https://asia-southeast2-ideal-trimitra.cloudfunctions.net/ideal-backend";
const baseUrlGoogleFunction = "https://asia-southeast2-autotrimitra.cloudfunctions.net";

// const baseUrlCrmAmartaVip = "http://localhost:9999/amartavip";
// const baseUrlBookingAmartaVip = "http://localhost:3000/finish-booking";

/** KEY */
const xApiKeyTrimitraCatalog = "5ukoYcllpl6lIeKsbeIPI4hOZGDszFVk1dDBddHi";
const xApiKeyAmartaVip = "wbEBxCen12pHWwBHMakz3ebKWmttdwr9BfYrZlw2";
const xApiKeyCdnUpload = "zXSa4iZwycS0O5kaoMvi2yAFiIpimTZ55MvvJLy0";
const xApiKeyAutotrimitra = "AIzaSyCToQTj8VyACsfBVFvxNTtS9Sq01eqEIsU";

export {
  baseUrlAmartaVip,
  xApiKeyAmartaVip,
  baseUrlCdnUpload,
  xApiKeyCdnUpload,
  baseUrlTrimitraCatalog,
  xApiKeyTrimitraCatalog,
  baseUrlAutotrimitra,
  xApiKeyAutotrimitra,
  baseUrlOtodis,
  baseUrlCrmAmartaVip,
  baseUrlBookingAmartaVip,
  baseurlBengkel,
  baseurlIdeal,
  baseUrlGoogleFunction,
};
