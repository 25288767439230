import axios from "axios";
import { crmAmartaVipResponse } from "./types/crmAmartaVipServicesTypes";
import { baseUrlCrmAmartaVip, baseUrlGoogleFunction } from "../../config/apiConfig/apiConfig";

class CrmAmartaVipServices {
  private axios = axios.create({
    baseURL: baseUrlCrmAmartaVip,
  });

  private axiosGF = axios.create({
    baseURL: baseUrlGoogleFunction,
  });

  public async getVehicle(params?: { vehicle_engine_number?: string }) {
    const queries = {
      ...(params?.vehicle_engine_number && {
        vehicle_engine_number: params.vehicle_engine_number,
      }),
    };
    try {
      return await this.axiosGF.get<crmAmartaVipResponse>("/amartavip__vehicle/vehicle", {
        params: {
          ...queries,
        },
      });
    } catch (e: any) {
      throw new Error(e);
    }
  }

  public async postActivity(post_data: any) {
    try {
      return await this.axios.post<any>("/activity", post_data);
    } catch (e: any) {
      throw new Error(e?.response?.data?.error?.message);
    }
  }

  public async getTestDrive(params: any) {
    const queries = {
      ...(params?.code && {
        code: params.code,
      }),
    };

    return await this.axios.get<crmAmartaVipResponse>("/activity/testdrive", {
      params: {
        ...queries,
      },
      headers: {
        "X-Api-Key": "NqWjTj6qpI7LK8JUKvkQz3isRY9NGU7faeWwv2ff",
      },
    });
  }

  public async updateTestdrive(post_data: any) {
    return await this.axios.put<any>("/activity/testdrive", post_data);
  }
}

export const crmAmartaVipServices = new CrmAmartaVipServices();
