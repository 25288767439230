import React, { Component } from "react";
import { withRouter } from "../../hoc/withRouter";

import MyProfileLoginHeader from "../../component/layouts/MyProfileLoginHeader";
import FooterSection from "../../component/pageSection/FooterSection";

import { Layout, Button, Col, Modal, Row, Spin, Input, Form } from "antd";
import { CheckCircleOutlined } from "@ant-design/icons";

import { appVersion } from "../../config/appDataConfig/appConfig";
import { FormFeedbackLeadStates } from "./types/FormFeedbackLeadTypes";
import { leadFeedbackService } from "../../services/ideal/LeadFeedbackService";
import SatisfactionLevel from "../../component/radio-checkbox/SatisfactionLevel";

export interface FormFeedbackLeadProps {}

class FormFeedbackLead extends Component<FormFeedbackLeadProps & any, FormFeedbackLeadStates> {
  constructor(props: FormFeedbackLeadProps & any) {
    super(props);

    const { match } = this.props;
    const clientPhone = match.params.clientPhone;

    this.state = {
      submitting: false,
      freezeStatus: false,
      processing: false,

      clientPhone: clientPhone,
      satisfactionLevel: "",
      feedbackComments: "",
      source: "crm.amarta.vip/leadfb",
    };
  }

  async componentDidMount() {
    try {
    } catch (e) {
      this.props.history.push("/not-allowed");
    }
  }

  onSatisfactionLevelChange = (e: any) => {
    this.setState({ satisfactionLevel: e.target.value });
  };

  onSaveFeedback = async () => {
    const currentState: any = { ...this.state };

    if (currentState.satisfactionLevel === "" || currentState.satisfactionLevel === undefined) {
      Modal.error({ title: "Error", content: "Mohon Memilih Tingkat Kepuasan" });
      return;
    }
    if (currentState.feedbackComments === "") {
      Modal.error({ title: "Error", content: "Mohon mengisi komentar" });
      return;
    }

    const dataPost = {
      client_phone: currentState.clientPhone,
      feedback_satisfaction_level: currentState.satisfactionLevel,
      feedback_comments: currentState.feedbackComments,
      source: currentState.source,
    };

    this.setState({ submitting: true });

    await leadFeedbackService
      .addFeedbackLead(dataPost)
      .then((result) => {
        this.setState({
          submitting: false,
        });

        this.resetFormState();
        Modal.success({
          title: "Success",
          content: "Data Berhasil di Tambahkan",
        });
      })
      .catch((error) => {
        this.setState({
          submitting: false,
        });

        Modal.error({ title: "Error", content: error.toString() });
      });
  };

  validateEmail = (email: string): boolean => {
    const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return regex.test(email);
  };

  resetFormState = () => {
    this.setState({
      feedbackComments: "",
    });
  };

  onFieldChange = <T extends keyof Pick<any, "clientPhone" | "feedbackComments">>(target: T, value: any) => {
    const currentState: any = { ...this.state };
    switch (target) {
      case "clientPhone":
        currentState.clientPhone = value?.replace(/[^0-9.]/g, "");
        break;
      case "feedbackComments":
        currentState.feedbackComments = value;
        break;
    }

    this.setState({
      ...currentState,
    });
  };

  render() {
    return (
      <React.Fragment>
        <Layout className="layout">
          <Row
            style={{
              backgroundColor: "#fff",
              paddingBottom: 5,
              borderBottom: "#f0f2f5 2px solid",
            }}
          >
            <Col xl={{ span: 20, offset: 2 }} lg={{ span: 20, offset: 2 }} md={{ span: 24 }} sm={{ span: 24 }} xs={{ span: 24 }}>
              <MyProfileLoginHeader title={"Feedback"} style={{ fontSize: 10 }} />
            </Col>
          </Row>
          <Col xl={{ span: 16, offset: 4 }} lg={{ span: 18, offset: 2 }} md={{ span: 24 }} sm={{ span: 24 }} xs={{ span: 24 }}>
            <div style={{ backgroundColor: "#fff", padding: "2vh" }}>
              <div style={this.state.processing ? { textAlign: "center", padding: 20, minHeight: 200 } : { display: "none" }}>
                <Spin style={{ marginTop: 20 }} size={"large"} tip="Loading..." />
              </div>
              <div style={!this.state.processing ? {} : { display: "none" }}>
                <Form layout="vertical">
                  <Row>
                    <Col xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 12 }} xs={{ span: 24 }} style={{ paddingRight: 10 }}>
                      <SatisfactionLevel satisfactionLevel={this.state.satisfactionLevel} onSatisfactionLevelChangeProps={this.onSatisfactionLevelChange} />
                      <Form.Item>
                        <i className={`text-small-grey-nopadding`}>No Telepon </i>
                        <Input
                          type="text"
                          value={this.state.clientPhone}
                          placeholder="Masukan Nomor Telepon Wa Aktif"
                          onChange={(event) => this.onFieldChange("clientPhone", event.target.value)}
                          disabled
                        />
                      </Form.Item>
                      <Form.Item>
                        <i className={`text-small-grey-nopadding`}>Komentar </i>
                        <Input.TextArea
                          value={this.state.feedbackComments}
                          placeholder="Tuliskan pengalaman, kritik & saran atas layanan "
                          onChange={(event) => this.onFieldChange("feedbackComments", event.target.value)}
                          autoSize={{ minRows: 10, maxRows: 10 }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row>
                    <Col xl={{ span: 24 }} lg={{ span: 24 }} md={{ span: 24 }} sm={{ span: 24 }} xs={{ span: 24 }} style={{ paddingTop: 10 }}>
                      <Button type="primary" onClick={this.onSaveFeedback} loading={this.state.submitting} danger>
                        Kirim Feedback <CheckCircleOutlined />
                      </Button>
                    </Col>
                    {/* <>Tingkat Kepuasan : {this.state.satisfactionLevel}</>
                    <br />
                    <br />
                    <>Kometar : {this.state.feedbackComments}</> */}
                  </Row>
                </Form>
              </div>
            </div>
          </Col>
        </Layout>
        <FooterSection version={appVersion} />
      </React.Fragment>
    );
  }
}

export default withRouter(FormFeedbackLead);
