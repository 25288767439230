import axios from "axios";
import { baseUrlGoogleFunction} from "../../config/apiConfig/apiConfig";

class LeadFeedbackService {
    private axios = axios.create({
        baseURL: baseUrlGoogleFunction,
    });

    public async addFeedbackLead(post_data:any) {
        try {
            return await this.axios.post<any>('/amartavip__leadsFeedback/amartavip/lead/feedback', post_data);
        } catch (e: any) {
            throw new Error(e?.response?.data?.error?.message);
        }
    }
}

export const leadFeedbackService = new LeadFeedbackService();
