import axios from "axios";
import { idealResponse } from "./types/idealServicesTypes";
import { baseurlIdeal, xApiKeyAmartaVip } from "../../config/apiConfig/apiConfig";

class LeadServices {
  private axios = axios.create({
    baseURL: baseurlIdeal,
    headers: {
      Authorization: "Basic bGVhZHNDbGllbnRBcHA6cER5PEEyVC5zKGYzYDZIWg==",
    },
    timeout: 60000,
  });

  public async getLead(params?: { vehicle_engine_number?: string }) {
    const queries = {
      ...(params?.vehicle_engine_number && {
        vehicle_engine_number: params.vehicle_engine_number,
      }),
    };
    try {
      return await this.axios.get<idealResponse>("/vehicle", {
        params: {
          ...queries,
        },
      });
    } catch (e: any) {
      throw new Error(e);
    }
  }

  public async addLead(post_data: any) {
    return await this.axios.post<any>("/leads/add-lead", post_data);
  }

  public async addFreeLead(post_data: any) {
    return await this.axios.post<any>("/leads/add-free-lead", post_data);
  }

  public async getGlobalNotesByPhoneNumber(params: { organizationCode: string; phoneNumber: string }) {
    const queries = {};
    try {
      return await this.axios.get<any>(`/leads/get-notes/${params.organizationCode}/${params.phoneNumber?.trim()}`, {
        headers: { "X-Api-Key": xApiKeyAmartaVip },
        params: { ...queries },
      });
    } catch (e: any) {
      throw new Error(e?.response?.data?.error?.message);
    }
  }
}

export const leadServices = new LeadServices();
