import React, { Component } from "react";
import { withRouter } from "../../hoc/withRouter";
import dayjs from "dayjs";

import MyProfileLoginHeader from "../../component/layouts/MyProfileLoginHeader";
import FooterSection from "../../component/pageSection/FooterSection";
import SelectVehicleBrand from "../../component/dynamicForm/SelectVehicleBrand";
import SelectVehicleModel from "../../component/dynamicForm/SelectVehicleModel";
import SelectVehicleVariant from "../../component/dynamicForm/SelectVehicleVariant";

import customParseFormat from "dayjs/plugin/customParseFormat";
import { Layout, Button, Col, Modal, Row, Spin, DatePicker, Form, Input, Upload, QRCode, Tooltip } from "antd";
import type { RangePickerProps } from "antd/es/date-picker";
import { CheckCircleOutlined, UploadOutlined, CopyOutlined, LoadingOutlined } from "@ant-design/icons";

import { appVersion } from "../../config/appDataConfig/appConfig";
import { FormTestDriveStates } from "./types/FormTestDriveTypes";
import { crmAmartaVipServices } from "../../services/amartaVip/CrmAmartaVipServices";
import { leadServices } from "../../services/ideal/LeadServices";

dayjs.extend(customParseFormat);
const dateFormat = "YYYY-MM-DD";

const disabledtestDriveDate: RangePickerProps["disabledDate"] = (current) => {
  return current && current < dayjs().subtract(1, "day").endOf("day");
};
const disabledDateTime = () => ({
  disabledHours: () => [0, 20, 21, 22, 23],
});

export interface FormTestDriveProps {}

class FormTestDrive extends Component<FormTestDriveProps & any, FormTestDriveStates> {
  constructor(props: FormTestDriveProps & any) {
    super(props);

    const searchParams = new URLSearchParams(this.props.location.search);
    const t = searchParams.get("t") || dayjs().format("YYYY-MM-DD HH:mm");
    const date1 = dayjs();
    const date2 = dayjs(t);
    const days = date2.diff(date1, "day");

    this.state = {
      submitting: false,
      freezeStatus: false,
      processing: false,

      dataClient: {},

      customerIdentityNumber: "",
      customerIdentityImage: "",
      customerName: "",
      customerPhone: "",
      customerEmail: "",
      testDriveDate: days > 0 ? dayjs(t).format(dateFormat) : dayjs().format("YYYY-MM-DD"),
      testDriveTime: dayjs(t).format("HH:mm"),
      vehicleCategory: "car",
      vehicleBrandUuid: "7835d023-762f-499a-84a5-539cb44f3580",
      vehicleBrandName: "chery",
      vehicleModelUuid: searchParams.get("m") || "",
      vehicleModelName: "",
      vehicleVariantUuid: "",
      vehicleVariantCode: "",
      vehicleVariantName: "",
      imageIdentification: null,
      validImageIdentification: true,
      eventName: searchParams.get("a") || "",

      agenCode: "",
      fetchAgentCodeFromLeadNumber: false,
      disableAgentCode: false,
      amartaVipIsLead: false,

      // customerIdentityNumber: "TES RORA",
      // customerIdentityImage: "TES RORA",
      // customerName: "TES RORA",
      // customerPhone: "089",
      // customerEmail: "RORA@INVENTO.CO.ID",

      t: t,
      sizeQr: 0,
    };
  }

  async componentDidMount() {
    try {
      window.addEventListener("resize", this.updateWindowDimensions);
      this.updateWindowDimensions();
    } catch (e) {
      this.props.history.push("/not-allowed");
    }
  }

  updateWindowDimensions = () => {
    const width = window.innerWidth;

    let sizeQr = 200;

    if (width <= 375) {
      sizeQr = 250;
      if (width <= 300) {
        sizeQr = 160;
      }
    } else if (width > 320 && width < 850) {
      sizeQr = 280;
    } else if (width > 850) {
      sizeQr = 280;
    } else if (width > 1024) {
      sizeQr = 280;
    }

    this.setState({
      sizeQr: sizeQr,
    });
  };

  onVehicleModelChange = (data: any) => {
    // console.log(data);

    this.setState({
      vehicleModelUuid: data?.value,
      vehicleModelName: data?.children,
      vehicleVariantUuid: "",
      vehicleVariantCode: "",
      vehicleVariantName: "",
    });
  };

  onVehicleVariantChange = (data: any) => {
    if (data) {
      this.setState({
        vehicleVariantUuid: data.value,
        vehicleVariantCode: data.code,
        vehicleVariantName: data.children,
      });
    }
  };

  onFieldChange = <T extends keyof Pick<any, "customerIdentityNumber" | "customerName" | "customerPhone" | "customerEmail" | "testDriveDate" | "testDriveTime" | "agenCode" | "eventName">>(
    target: T,
    value: any
  ) => {
    const currentState: any = { ...this.state };
    switch (target) {
      case "customerIdentityNumber":
        currentState.customerIdentityNumber = value;
        break;
      case "customerName":
        currentState.customerName = value;
        break;
      case "customerPhone":
        currentState.customerPhone = value?.replace(/[^0-9.]/g, "");
        break;
      case "customerEmail":
        currentState.customerEmail = value;
        break;
      case "testDriveDate":
        currentState.firstServiceDate = value;
        break;
      case "testDriveTime":
        currentState.firstServiceTime = value;
        break;
      case "agenCode":
        currentState.agenCode = value;
        break;
      case "eventName":
        currentState.eventName = value;
        break;
    }

    this.setState({
      ...currentState,
    });
  };

  onSelectChange = <T extends keyof Pick<any, "vehicleBrandUuid">>(target: T, value: string, i: any) => {
    const currentState: any = { ...this.state };
    switch (target) {
      case "vehicleBrandUuid":
        currentState.vehicleBrandUuid = value;
        currentState.vehicleBrandName = i.children;
        break;
    }

    this.setState({
      ...currentState,
    });
  };

  beforeUploadFile = async (file: any) => {
    if (this.state.customerIdentityNumber === "") {
      Modal.error({ title: "Error", content: "Mohon isi nomor SIM anda" });
      return false;
    }
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      Modal.error({ title: "Error", content: "Format File Bukan JPG/PNG" });
      return false;
    }
    // const isLt2M = file.size / 1024 / 1024 < 2;
    // if (!isLt2M) {
    //   Modal.error({ title: "Error", content: "File Size harus kurang dari 2MB" });
    //   return false;
    // }

    if (isJpgOrPng) {
      // if (isJpgOrPng && isLt2M) {
      let reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {
        this.setState({
          imageIdentification: reader.result,
        });
      };

      // const url = URL.createObjectURL(file);

      this.setState({
        imageIdentification: file,
        submitting: true,
      });
    } else {
      this.setState({
        imageIdentification: null,
        validImageIdentification: false,
      });
    }
  };

  onUploadImage = (info: any) => {
    // console.log("status", info.file.status);

    if (info.file.status === "done") {
      // console.log(info.file.response);

      if (info.file.response.data.url_document) {
        this.setState({
          customerIdentityImage: info.file.response.data.url_document,
          validImageIdentification: true,
          submitting: false,
        });
      } else {
        this.setState({
          customerIdentityImage: "",
          validImageIdentification: false,
        });
      }
    }
  };

  validateEmail = (email: string): boolean => {
    const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return regex.test(email);
  };

  resetFormState = () => {
    this.setState({
      customerIdentityNumber: "",
      customerIdentityImage: "",
      customerName: "",
      customerPhone: "",
      customerEmail: "",
      vehicleVariantUuid: "",
      vehicleVariantCode: "",
      vehicleVariantName: "",
      imageIdentification: null,
      validImageIdentification: true,
      agenCode: "",
    });
  };

  onDownloadQRCode = () => {
    const canvas = document.getElementById("myqrcode")?.querySelector<HTMLCanvasElement>("canvas");
    if (canvas) {
      const url = canvas.toDataURL();
      const a = document.createElement("a");
      a.download = "QRCode.png";
      a.href = url;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  };

  copyToClipboard = async (data: any) => {
    await navigator.clipboard.writeText(data);
  };

  onSaveBook = async () => {
    const currentState: any = { ...this.state };

    // return;

    // console.log("vehicleModelName", currentState.vehicleModelName);

    if (currentState.customerEmail !== "") {
      if (!this.validateEmail(currentState.customerEmail)) {
        Modal.error({ title: "Error", content: "Mohon masukan email dengan benar" });
        return;
      }
    }
    if (currentState.customerName === "") {
      Modal.error({ title: "Error", content: "Mohon Lengkapi Nama" });
      return;
    }
    if (currentState.customerEmail === "") {
      Modal.error({ title: "Error", content: "Mohon Lengkapi Email" });
      return;
    }
    if (currentState.customerPhone === "") {
      Modal.error({ title: "Error", content: "Mohon Lengkapi No Telepon Aktif" });
      return;
    }
    if (currentState.testDriveDate === "") {
      Modal.error({ title: "Error", content: "Mohon Lengkapi Tanggal Tes Drive" });
      return;
    }
    if (currentState.testDriveTime === "") {
      Modal.error({ title: "Error", content: "Mohon Lengkapi Waktu Tes Drive" });
      return;
    }
    if (currentState.vehicleBrandUuid === "") {
      Modal.error({ title: "Error", content: "Mohon Lengkapi Merk Kendaraan yang akan di Tes Drive" });
      return;
    }
    if (currentState.vehicleModelUuid === "") {
      Modal.error({ title: "Error", content: "Mohon Lengkapi Model Kendaraan yang akan di Tes Drive" });
      return;
    }
    if (currentState.vehicleVariantUuid === "") {
      Modal.error({ title: "Error", content: "Mohon Lengkapi Variant Kendaraan yang akan di Tes Drive" });
      return;
    }

    if (!currentState.customerIdentityImage || currentState.customerIdentityImage === "") {
      Modal.error({ title: "Error", content: "Mohon upload gambar SIM" });
      return;
    }

    if (currentState.validImageIdentification === false) {
      Modal.error({ title: "Error", content: "Mohon upload gambar SIM" });
      return;
    }

    this.setState({ submitting: true });

    const postData = {
      company_code: "chery",
      client_name: currentState.customerName,
      client_phone: currentState.customerPhone,
      client_email: currentState.customerEmail,
      client_identity_number: currentState.customerIdentityNumber,
      client_identity_image: currentState.customerIdentityImage,
      brand_uuid: currentState.vehicleBrandUuid,
      brand_name: currentState.vehicleBrandName,
      model_uuid: currentState.vehicleModelUuid,
      model_name: currentState.vehicleModelName,
      variant_uuid: currentState.vehicleVariantUuid,
      variant_code: currentState.vehicleVariantCode,
      variant_name: currentState.vehicleVariantName,
      agen_code: currentState.agenCode,
      is_leads: currentState.amartaVipIsLead,
      activity_name: currentState.eventName,
      activity_date: currentState.testDriveDate,
      activity_time: currentState.testDriveTime,
      activity_type: "TESTDRIVE",
      source: "h1book.amarta.vip",
    };

    await crmAmartaVipServices
      .postActivity(postData)
      .then((result) => {
        this.setState({
          submitting: false,
        });

        const code = result?.data?.data?.code;

        // console.log(result?.data?.data?.code);

        Modal.success({
          title: "Data Berhasil Disimpan",
          content: (
            <React.Fragment>
              <div>
                <div id="myqrcode" style={{ display: "flex", justifyContent: "center" }}>
                  <QRCode value={code} size={this.state.sizeQr} bordered={true} />
                </div>
                <div style={{ textAlign: "center", fontSize: 14, fontWeight: "bold" }}>Behasil Menyimpan data</div>
                <div style={{ textAlign: "center" }}>
                  Waktu Buat: <text style={{ fontWeight: "bold" }}>{dayjs().format("YYYY-MM-DD HH:mm:ss")} </text>
                  <br />
                  Waktu Test Drive: <text style={{ fontWeight: "bold" }}>{dayjs(currentState.testDriveDate + " " + currentState.testDriveTime).format("YYYY-MM-DD HH:mm:ss")} </text>
                  <br />
                  Nama : <text style={{ fontWeight: "bold" }}>{currentState.customerName} </text>
                  <br />
                  Kode:&nbsp;
                  <text style={{ fontWeight: "bold" }}>
                    {code}
                    <Tooltip title="Klik Untuk Mencopy Kode" color={"cyan"}>
                      <CopyOutlined className={"small-avatar-link"} onClick={(event) => this.copyToClipboard(code)} />
                    </Tooltip>
                  </text>
                  &nbsp; Mohon download dan simpan kode QR dengan menekan tombol berikut.
                </div>
                <div style={{ paddingTop: 10, display: "flex", justifyContent: "center" }}>
                  <Button type="primary" onClick={this.onDownloadQRCode}>
                    Download
                  </Button>
                </div>
              </div>
            </React.Fragment>
          ),
        });

        this.resetFormState();
        // setTimeout(() => {
        //   window.location.reload();
        // }, 3000);
      })
      .catch((error) => {
        this.setState({
          submitting: false,
        });

        Modal.error({ title: "Error", content: error.toString() });
      });
  };

  phoneNumberBlur = async () => {
    this.setState({
      agenCode: "",
      fetchAgentCodeFromLeadNumber: true,
      disableAgentCode: false,
      amartaVipIsLead: false,
      submitting: true,
    });

    try {
      const fetchResult = await leadServices.getGlobalNotesByPhoneNumber({
        organizationCode: "amartachery",
        phoneNumber: this.state.customerPhone.substring(0, 2) === "08" ? this.state.customerPhone.replace(/^.{2}/g, "628") : this.state.customerPhone,
      });

      if (fetchResult?.data?.success?.data?.length > 0) {
        const agenCode = fetchResult?.data?.success?.data[0]?.agentCode;
        this.setState({
          agenCode: agenCode,
          fetchAgentCodeFromLeadNumber: false,
          disableAgentCode: true,
          amartaVipIsLead: true,
          submitting: false,
        });
      } else {
        this.setState({
          fetchAgentCodeFromLeadNumber: false,
          disableAgentCode: false,
          submitting: false,
        });
      }
    } catch (e) {
      this.setState({
        fetchAgentCodeFromLeadNumber: false,
        disableAgentCode: false,
        submitting: false,
      });
    }
  };

  render() {
    return (
      <React.Fragment>
        <Layout className="layout">
          <Row style={{ backgroundColor: "#fff", paddingBottom: 5, borderBottom: "#f0f2f5 2px solid" }}>
            <Col xl={{ span: 20, offset: 2 }} lg={{ span: 20, offset: 2 }} md={{ span: 24 }} sm={{ span: 24 }} xs={{ span: 24 }}>
              <MyProfileLoginHeader title={"Test Drive Kendaraan"} style={{ fontSize: 10 }} />
            </Col>
          </Row>
          <Col xl={{ span: 16, offset: 4 }} lg={{ span: 18, offset: 2 }} md={{ span: 24 }} sm={{ span: 24 }} xs={{ span: 24 }}>
            <div style={{ backgroundColor: "#fff", padding: "2vh" }}>
              <div style={this.state.processing ? { textAlign: "center", padding: 20, minHeight: 200 } : { display: "none" }}>
                <Spin style={{ marginTop: 20 }} size={"large"} tip="Loading..." />
              </div>
              <div style={!this.state.processing ? {} : { display: "none" }}>
                <Form layout="vertical">
                  <Row>
                    <Col xl={{ span: 24 }} lg={{ span: 24 }} md={{ span: 24 }} sm={{ span: 24 }} xs={{ span: 24 }} style={{ padding: 5 }}>
                      <Row>
                        <Col xl={{ span: 24 }} lg={{ span: 24 }} md={{ span: 24 }} sm={{ span: 24 }} xs={{ span: 24 }} style={{ paddingLeft: 10, paddingRight: 10 }}>
                          <Row>
                            <Col xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 24 }} sm={{ span: 24 }} xs={{ span: 24 }} style={{ paddingRight: 5 }}>
                              <Form.Item>
                                <i className={`text-small-grey-nopadding`}>No SIM *</i>
                                <Input
                                  type="text"
                                  value={this.state.customerIdentityNumber}
                                  placeholder="Masukan No SIM Anda"
                                  onChange={(event) => this.onFieldChange("customerIdentityNumber", event.target.value)}
                                />
                              </Form.Item>

                              <Form.Item>
                                <i className={`text-small-grey-nopadding`}>Nama *</i>
                                <Input type="text" value={this.state.customerName} placeholder="Masukan Nama Anda" onChange={(event) => this.onFieldChange("customerName", event.target.value)} />
                              </Form.Item>

                              <Form.Item>
                                <i className={`text-small-grey-nopadding`}>No Telepon *</i>
                                <Input
                                  type="text"
                                  value={this.state.customerPhone}
                                  placeholder="Masukan Nomor Telepon Wa Aktif"
                                  onChange={(event) => this.onFieldChange("customerPhone", event.target.value)}
                                  onBlur={this.phoneNumberBlur}
                                />
                              </Form.Item>

                              <Form.Item>
                                <i className={`text-small-grey-nopadding`}>Email </i>
                                <Input value={this.state.customerEmail} placeholder="Masukan Email Aktif" onChange={(event) => this.onFieldChange("customerEmail", event.target.value)} />
                              </Form.Item>

                              <Row>
                                <Col xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 12 }} xs={{ span: 24 }} style={{ paddingRight: 5 }}>
                                  <Form.Item>
                                    <i className={`text-small-grey-nopadding`}>Tanggal Test Drive *</i>
                                    <DatePicker
                                      name="testDriveDate"
                                      format={"YYYY-MM-DD"}
                                      style={{ width: "100%" }}
                                      disabledDate={disabledtestDriveDate}
                                      defaultValue={dayjs(this.state.testDriveDate)}
                                      onChange={(date, dateString) => this.onFieldChange("testDriveDate", dateString)}
                                    />
                                  </Form.Item>
                                </Col>
                                <Col xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 12 }} xs={{ span: 24 }}>
                                  <Form.Item>
                                    <i className={`text-small-grey-nopadding`}>Waktu Test Drive *</i>
                                    <DatePicker
                                      picker="time"
                                      name="testDriveTime"
                                      format="HH:mm"
                                      onChange={(date, dateString) => this.onFieldChange("testDriveTime", dateString)}
                                      disabledTime={disabledDateTime}
                                      defaultValue={dayjs(this.state.t)}
                                      style={{ width: "100%" }}
                                    />
                                  </Form.Item>
                                </Col>
                              </Row>

                              <Form.Item>
                                <i className={`text-small-grey-nopadding`}>Merk Kendaraan *</i>
                                <SelectVehicleBrand value={this.state.vehicleBrandUuid} onChange={(value: string, i: any) => this.onSelectChange("vehicleBrandUuid", value, i)} />
                              </Form.Item>

                              <Form.Item>
                                <i className={`text-small-grey-nopadding`}>Model Kendaraan *</i>
                                <SelectVehicleModel
                                  value={this.state.vehicleModelUuid}
                                  onVehicleModelChangeProps={this.onVehicleModelChange}
                                  vehicleCategory={this.state.vehicleCategory}
                                  vehicleBrandUuid={this.state.vehicleBrandUuid}
                                />
                              </Form.Item>

                              <Form.Item>
                                <i className={`text-small-grey-nopadding`}>Variant Kendaraan *</i>
                                <SelectVehicleVariant
                                  value={this.state.vehicleVariantUuid}
                                  onVehicleVariantChangeProps={this.onVehicleVariantChange}
                                  vehicleCategory={this.state.vehicleCategory}
                                  vehicleBrandUuid={this.state.vehicleBrandUuid}
                                  vehicleModelUuid={this.state.vehicleModelUuid}
                                  cityGroup=""
                                />
                              </Form.Item>

                              <Form.Item>
                                <i className={`text-small-grey-nopadding`}>Kode Agen </i>
                                <Input
                                  suffix={this.state.fetchAgentCodeFromLeadNumber && <Spin indicator={<LoadingOutlined />} />}
                                  disabled={this.state.disableAgentCode}
                                  type="text"
                                  value={this.state.agenCode}
                                  placeholder="Masukan Kode Agen"
                                  onChange={(event) => this.onFieldChange("agenCode", event.target.value)}
                                />
                              </Form.Item>

                              <Form.Item>
                                <i className={`text-small-grey-nopadding`}>Acara</i>
                                <Input type="text" value={this.state.eventName} placeholder="Masukan Acara" onChange={(event) => this.onFieldChange("eventName", event.target.value)} />
                              </Form.Item>
                            </Col>
                            <Col xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 24 }} sm={{ span: 24 }} xs={{ span: 24 }} style={{ paddingLeft: 5 }}>
                              <i className={`text-small-grey-nopadding`}>Upload SIM *</i>
                              <br />
                              <Upload
                                maxCount={1}
                                listType="picture"
                                beforeUpload={this.beforeUploadFile}
                                // action="http://localhost:9999/upload-document/document-data"
                                action="https://hbxhwzeej9.execute-api.ap-southeast-1.amazonaws.com/v1/upload-document/document-data"
                                data={{
                                  document_name: this.state.customerIdentityNumber,
                                  document_type: "driving_license",
                                  source: "https://h1book.amarta.vip/testdrive/amartachery",
                                  notes: "DRIVING LICENSE IMAGE",
                                }}
                                onChange={this.onUploadImage}
                                name="image"
                              >
                                <Button icon={<UploadOutlined />} loading={this.state.submitting}>
                                  Select File
                                </Button>
                              </Upload>
                              <Row style={{ marginTop: "10px" }}>
                                <Col xl={{ span: 20 }} lg={{ span: 20 }} md={{ span: 24 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                                  <img alt={''} src={this.state.imageIdentification} style={{ width: "100%", height: "280px" }} />
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row>
                        <Col xl={{ span: 8 }} lg={{ span: 8 }} md={{ span: 12 }} sm={{ span: 24 }} xs={{ span: 24 }} style={{ padding: 10 }}>
                          <Button type="primary" onClick={this.onSaveBook} loading={this.state.submitting} danger>
                            Booking Test Drive <CheckCircleOutlined />
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Form>
              </div>
            </div>
          </Col>
        </Layout>
        <FooterSection version={appVersion} />
      </React.Fragment>
    );
  }
}

export default withRouter(FormTestDrive);
