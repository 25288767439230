const production = true;

/* CHERY */
const appData = {
  appVersion: "v-0.2.0",
  appProduction: production,
  appCompanyCode: "amartavip",
  appCompanyName: "Form Amartavip",
  appCode: "amartavip",
  appAbbreviation: "amartavip",
};

export const appProduction = appData?.appProduction;
export const appCompanyCode = appData?.appCompanyCode;
export const appCompanyName = appData?.appCompanyName;
export const appCode = appData?.appCode;
export const appAbbreviation = appData?.appAbbreviation;
export const appVersion = appData?.appVersion;
