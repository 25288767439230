import React, { Component } from "react";
import Select from "antd/lib/select";

class SelectVehicleBrand extends Component<any, any> {
    private readonly initState!: any;

    constructor(props: any) {
        super(props);

        this.initState = {
            dataBrand: [{
                "brand_uuid": "7835d023-762f-499a-84a5-539cb44f3580",
                "brand_name": "chery",
                "country": "CHN",
                "founded_year": 1997,
                "url_image": "https://storage.googleapis.com/autotrimitra-public/brands/chery.png",
                "url_info": null,
                "active": true
            }],
            selectBrandValue: null,
        }
        this.state = { ...this.initState }
    }

    fetchDealerList = () => {
        try {
            // dealerServices.getDealers({}).then(dataFetchDealer => {
            //     let listOption = dataFetchDealer.data.data.map((d: { dealer_code: string, dealer_name: string }) => {
            //         return { dealer_code: d.dealer_code, dealer_name: d.dealer_name };
            //     });
            //     this.setState({
            //         dataBrand: listOption,
            //         selectBrandValue: this.props.dealer_code ?? null,
            //     });
            // });
        } catch (e) {
            this.setState({
                dataCity: [],
                selectCityValue: null,
            });
        }
    }

    onChange = (value: any, option: any) => {
        this.setState({
            selectBrandValue: value,
        });

    }

    render() {
        return (
            <Select
                style={{ minWidth: "200px", width: "100%" }}
                showSearch
                placeholder="Pilih Brand Kendaraan"
                optionFilterProp="children"
                onChange={this.props.onChangeValue}
                value={this.props.value}
                filterOption={(input: any, option: any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                filterSort={(optionA: any, optionB: any) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
            >
                {
                    this.state.dataBrand.map((item: any, i: number) =>
                        <Select.Option key={item?.brand_uuid} value={item?.brand_uuid}>{item?.brand_name}</Select.Option>
                    )
                }
            </Select>
        );
    }
}

export default SelectVehicleBrand;
