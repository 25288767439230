import React, { Component } from "react";
import Select from "antd/lib/select";
import { vehicleServices } from "../../services/autotrimitra/VehicleServices";

class SelectVehicleModel extends Component<any, any> {
    private readonly initState!: any;

    constructor(props: any) {
        super(props);

        this.initState = {
            dataVehicleModel: [],
            vehicleModelUuid: null,
            vehicleModelName: null,
        }
        this.state = { ...this.initState }
    }

    async componentDidMount() {
        await this.fetchVehicleModelData(this.props.vehicleCategory, this.props.vehicleBrandUuid, this.props.value);
        await this.onValueChange(this.props.value);
    }

    onValueChange = (value: any) => {
        this.setState({
            vehicleModelUuid: value,
        });
    }

    onChange = async (value: any, option: any) => {
        this.setState({
            vehicleModelUuid: value,
            vehicleModelName: option.children
        });
        this.props.onVehicleModelChangeProps(option);
    }

    fetchVehicleModelData = (vehicleCategory: string, vehicleBrandUuid: string, vehicleModelUuid: string) => {

        if (vehicleBrandUuid) {
            // FETCH API
            try {
                vehicleServices.getModelList({
                    category: vehicleCategory,
                    brand_uuid: vehicleBrandUuid,
                }).then(dataFetched => {
                    let vehicleModelName = this.state.vehicleModelName;
                    let listOption = dataFetched.data.data.map((d: { model_uuid: string, model_name: string }) => {
                        if (vehicleModelUuid === d.model_uuid) {
                            vehicleModelName = d.model_name
                        }
                        return { value: d.model_uuid, name: d.model_name };
                    });

                    this.setState({
                        dataVehicleModel: listOption,
                        vehicleModelUuid: vehicleModelUuid,
                        vehicleModelName: vehicleModelName,
                    });

                    if (vehicleModelUuid !== "" || vehicleModelUuid !== null) {
                        this.props.onVehicleModelChangeProps({
                            "key": vehicleModelUuid,
                            "value": vehicleModelUuid,
                            "children": vehicleModelName
                        });
                    }
                });
            } catch (e) {
                this.setState({
                    dataVehicleModel: [],
                    vehicleModelUuid: null,
                    vehicleModelName: null,
                });
            }
        } else {
            this.setState({
                dataVehicleModel: [],
                vehicleModelUuid: null,
                vehicleModelName: null,
            });
        }
    }

    async componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any) {
        if (this.props.vehicleBrandUuid !== prevProps.vehicleBrandUuid) {
            await this.fetchVehicleModelData(this.props.vehicleCategory, this.props.vehicleBrandUuid, this.props.value);
        }
        if (this.props.value !== prevProps.value) {
            this.onValueChange(this.props.value);
        }
        if (this.props.time !== prevProps.time) {
            this.onValueChange(this.props.value);
        }
    }

    promisedSetState = (newState: any) => new Promise((resolve: any) => this.setState(newState, resolve));

    render() {
        return (
            <Select
                showSearch
                placeholder="Pilih Model Kendaraan"
                optionFilterProp="children"
                onChange={this.onChange}
                style={{ width: "100%" }}
                filterOption={(input: any, option: any) =>
                    option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                value={this.state.vehicleModelUuid}
            >
                {
                    this.state.dataVehicleModel.map((item: any, i: number) =>
                        <Select.Option key={item?.value} value={item?.value?.toLowerCase()}>{item?.name}</Select.Option>
                    )
                }

            </Select>
        );
    }
}

export default SelectVehicleModel;
