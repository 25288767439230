import React, { Component } from "react";
import { withRouter } from "../../hoc/withRouter";
import { QrReader } from "react-qr-reader";

import MyProfileLoginHeader from "../../component/layouts/MyProfileLoginHeader";
import FooterSection from "../../component/pageSection/FooterSection";

import { Layout, Button, Col, Modal, Row, Spin, Input, Form, Space } from "antd";
import { CheckCircleOutlined, SearchOutlined } from "@ant-design/icons";

import { appVersion } from "../../config/appDataConfig/appConfig";
import { FormTestDriveCheckStates } from "./types/FormTestDriveCheckTypes";
import { crmAmartaVipServices } from "../../services/amartaVip/CrmAmartaVipServices";

export interface FormTestDriveCheckProps {
}

class FormTestDriveCheck extends Component<FormTestDriveCheckProps & any, FormTestDriveCheckStates> {
  constructor(props: FormTestDriveCheckProps & any) {
    super(props);

    this.state = {
      submitting: false,
      freezeStatus: false,
      processing: false,

      testDriveCode: "",
      disabledtestDriveCode: false,
      clientName: "",
      startTestDrive: "",
      endTestDrive: "",
      agenCode: "",
      agenName: "",
      statusTestDrive: "",
      notes: "",
      showNotes: false,
      textButton: "",
      addTextDesc: "",
      disabledButton: true
    };
  }

  async componentDidMount() {
    try {
    } catch (e) {
      this.props.history.push("/not-allowed");
    }
  }

  handleScan = (data: string | null) => {
    if (data) {
      this.setState({
        testDriveCode: data
      });
      console.log("data QR", data);
    }
  };

  handleError = (error: Error) => {
    console.error("error scan", error);
  };

  onCheckTestDrive = async () => {
    const code = this.state.testDriveCode;

    if (code === "" || code === undefined) {
      Modal.error({ title: "Error", content: "Mohon Isi Test Drive Code" });
      return;
    }

    this.setState({ submitting: true });

    await crmAmartaVipServices
      .getTestDrive({
        code: code
      })
      .then((result) => {
        this.setState({
          submitting: false
        });

        if (result.data.success === true) {
          const dataTestDrive = result?.data?.data[0];
          if (dataTestDrive.test_drive_status === "UNTESTED") {
            this.setState({
              textButton: "Mulai Test Drive",
              agenCode: dataTestDrive.agen_code,
              agenName: dataTestDrive.agen_name,
              notes: "",
              showNotes: false,
              disabledButton: false,
              startTestDrive: "",
              endTestDrive: "",
              addTextDesc: "",
              statusTestDrive: "UNTESTED"
            });
          } else if (dataTestDrive.test_drive_status === "TESTING") {
            this.setState({
              textButton: "Akhiri Test Drive",
              agenCode: dataTestDrive.test_drive_agen_code,
              agenName: dataTestDrive.test_drive_agen_name,
              notes: "",
              showNotes: true,
              disabledButton: false,
              startTestDrive: dataTestDrive.test_drive_start_time,
              endTestDrive: "",
              addTextDesc: "sedang berlangsung",
              statusTestDrive: "TESTING"
            });
          } else if (dataTestDrive.test_drive_status === "TESTED") {
            this.setState({
              textButton: "Test Drive telah Berakhir",
              agenCode: dataTestDrive.test_drive_agen_code,
              agenName: dataTestDrive.test_drive_agen_name,
              notes: dataTestDrive.notes,
              showNotes: true,
              disabledButton: true,
              startTestDrive: dataTestDrive.test_drive_start_time,
              endTestDrive: dataTestDrive.test_drive_end_time,
              addTextDesc: "sudah Berakhir",
              statusTestDrive: "TESTED"
            });
          }
        }
      })
      .catch((error) => {
        this.setState({
          submitting: false
        });

        const dataError = error?.response?.data.error?.message;
        Modal.error({
          title: "Error",
          content: dataError
        });
      });
  };

  onChangeCode = async (code: string) => {
    if (code === "" || code === undefined) {
      Modal.error({ title: "Error", content: "Mohon Isi Test Drive Code" });
      return;
    }

    this.setState({ submitting: true });

    await crmAmartaVipServices
      .getTestDrive({
        code: code
      })
      .then((result) => {
        this.setState({
          submitting: false
        });

        if (result.data.success === true) {
          const dataTestDrive = result?.data?.data[0];
          if (dataTestDrive.test_drive_status === "UNTESTED") {
            this.setState({
              textButton: "Mulai Test Drive",
              agenCode: dataTestDrive.agen_code,
              agenName: dataTestDrive.agen_name,
              notes: "",
              showNotes: false,
              disabledButton: false,
              startTestDrive: "",
              endTestDrive: "",
              addTextDesc: "",
              statusTestDrive: "UNTESTED"
            });
          } else if (dataTestDrive.test_drive_status === "TESTING") {
            this.setState({
              textButton: "Akhiri Test Drive",
              agenCode: dataTestDrive.test_drive_agen_code,
              agenName: dataTestDrive.test_drive_agen_name,
              notes: "",
              showNotes: true,
              disabledButton: false,
              startTestDrive: dataTestDrive.test_drive_start_time,
              endTestDrive: "",
              addTextDesc: "sedang berlangsung",
              statusTestDrive: "TESTING",
              disabledtestDriveCode: true
            });
          } else if (dataTestDrive.test_drive_status === "TESTED") {
            this.setState({
              textButton: "Test Drive telah Berakhir",
              agenCode: dataTestDrive.test_drive_agen_code,
              agenName: dataTestDrive.test_drive_agen_name,
              notes: dataTestDrive.notes,
              showNotes: true,
              disabledButton: true,
              startTestDrive: dataTestDrive.test_drive_start_time,
              endTestDrive: dataTestDrive.test_drive_end_time,
              addTextDesc: "sudah Berakhir",
              statusTestDrive: "TESTED"
            });
          }
        }
      })
      .catch((error) => {
        this.setState({
          submitting: false
        });

        const dataError = error?.response?.data.error?.message;
        Modal.error({
          title: "Error",
          content: dataError
        });
      });
  };

  onButtonStart = async () => {
    const currentState: any = { ...this.state };

    if (currentState.testDriveCode === "") {
      Modal.error({ title: "Error", content: "Test Drive tidak boleh kosong" });
      return;
    }
    if (currentState.agenCode === "") {
      Modal.error({ title: "Error", content: "Agen Code tidak boleh kosong" });
      return;
    }
    if (currentState.agenName === "") {
      Modal.error({ title: "Error", content: "Agen Name tidak boleh kosong" });
      return;
    }

    const dataPost = {
      source: "crm.amarta.vip",
      activity_type: "TESTDRIVE",
      update_type: "",
      code: currentState.testDriveCode,
      agen_code: currentState.agenCode,
      notes: ""
    };

    if (currentState.statusTestDrive === "UNTESTED") {
      dataPost.update_type = "STARTTESTDRIVE";
    } else if (currentState.statusTestDrive === "TESTING") {
      dataPost.update_type = "ENDTESTDRIVE";
      dataPost.notes = currentState.notes;
    } else if (currentState.statusTestDrive === "TESTED") {
      Modal.error({ title: "Error", content: "Test Drive sudah berakhir" });
      return;
    }

    this.setState({ submitting: true });

    await crmAmartaVipServices
      .updateTestdrive(dataPost)
      .then((result) => {
        this.setState({
          submitting: false
        });

        if (currentState.statusTestDrive === "UNTESTED") {
          this.setState({
            textButton: "Akhiri Test Drive",
            notes: "",
            showNotes: true,
            disabledButton: false,
            addTextDesc: "sedang berlangsung",
            statusTestDrive: "TESTING",
            disabledtestDriveCode: true
          });

          Modal.success({
            title: "Success",
            content: "Test Drive telah dimulai"
          });
        } else if (currentState.statusTestDrive === "TESTING") {
          this.resetFormState();
          Modal.success({
            title: "Success",
            content: (
              <React.Fragment>
                <p>Test Drive telah berakhir, Mohon lengkapi form survey konsumen pada link berikut: <br/><br/> <a href={`/vehicle-survey/testdrive/${this.state.testDriveCode}`}> <Button type="primary">Lakukan Survey</Button></a></p>
              </React.Fragment>
            ),
            okButtonProps: { style: { display: "none" } }
          });
        }
      })
      .catch((error) => {
        this.setState({
          submitting: false
        });

        const dataError = error?.response?.data.error?.message;
        Modal.error({
          title: "Error",
          content: dataError
        });
      });
  };

  resetFormState = () => {
    this.setState({
      testDriveCode: "",
      clientName: "",
      startTestDrive: "",
      endTestDrive: "",
      agenCode: "",
      agenName: "",
      statusTestDrive: "",
      notes: "",
      showNotes: false,
      textButton: "",
      addTextDesc: "",
      disabledButton: true,
      disabledtestDriveCode: false
    });
  };

  onFieldChange = <T extends keyof Pick<any, "testDriveCode" | "agenCode" | "agenName" | "notes">>(target: T, value: any) => {
    const currentState: any = { ...this.state };
    switch (target) {
      case "testDriveCode":
        currentState.testDriveCode = value;
        break;
      case "agenCode":
        currentState.agenCode = value;
        break;
      case "agenName":
        currentState.agenName = value;
        break;
      case "notes":
        currentState.notes = value;
        break;
    }

    this.setState({
      ...currentState
    });
  };

  render() {
    const qrReaderStyle = {
      width: "100%"
    };

    const qrReaderProps = {
      delay: 1000,
      onError: this.handleError,
      onScan: this.handleScan,
      style: qrReaderStyle,
      facingMode: "rear",
      constraints: {
        facingMode: "environment"
      } as MediaTrackConstraints
    };

    return (
      <React.Fragment>
        <Layout className="layout">
          <Row
            style={{
              backgroundColor: "#fff",
              paddingBottom: 5,
              borderBottom: "#f0f2f5 2px solid"
            }}
          >
            <Col xl={{ span: 20, offset: 2 }} lg={{ span: 20, offset: 2 }} md={{ span: 24 }} sm={{ span: 24 }} xs={{ span: 24 }}>
              <MyProfileLoginHeader title={"Mulai Test Drive"} style={{ fontSize: 10 }} />
            </Col>
          </Row>
          <Col xl={{ span: 16, offset: 4 }} lg={{ span: 18, offset: 2 }} md={{ span: 24 }} sm={{ span: 24 }} xs={{ span: 24 }}>
            <div style={{ backgroundColor: "#fff", padding: "2vh" }}>
              <div style={this.state.processing ? { textAlign: "center", padding: 20, minHeight: 200 } : { display: "none" }}>
                <Spin style={{ marginTop: 20 }} size={"large"} tip="Loading..." />
              </div>
              <div style={!this.state.processing ? {} : { display: "none" }}>
                <Form layout="vertical">
                  <Row>
                    <Col xl={{ span: 6 }} lg={{ span: 6 }} md={{ span: 6 }} sm={{ span: 6 }} xs={{ span: 24 }} style={{ paddingRight: 10 }}>
                      <QrReader
                        {...qrReaderProps}
                        onResult={(result: any, error) => {
                          if (!!result) {
                            if (this.state.testDriveCode !== result?.text && this.state.disabledtestDriveCode === false) {
                              this.setState({
                                testDriveCode: result?.text
                              });

                              this.onChangeCode(result?.text);
                            }
                          }
                        }}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 12 }} xs={{ span: 24 }} style={{ paddingRight: 10 }}>
                      <Form.Item>
                        <i className={`text-small-grey-nopadding`}>Test Drive Code </i>
                        <Space.Compact style={{ width: "100%" }}>
                          <Input
                            type="text"
                            value={this.state.testDriveCode}
                            onChange={(event) => this.onFieldChange("testDriveCode", event.target.value)}
                            disabled={this.state.disabledtestDriveCode}
                          />
                          <Button type="primary" icon={<SearchOutlined />} danger onClick={this.onCheckTestDrive} />
                        </Space.Compact>
                      </Form.Item>
                      <Form.Item>
                        <i className={`text-small-grey-nopadding`}>Agen Code </i>
                        <Input type="text" value={this.state.agenCode} onChange={(event) => this.onFieldChange("agenCode", event.target.value)} />
                      </Form.Item>
                      {/* <Form.Item>
                        <i className={`text-small-grey-nopadding`}>Agen Name </i>
                        <Input type="text" value={this.state.agenName} onChange={(event) => this.onFieldChange("agenName", event.target.value)} />
                      </Form.Item> */}
                      <Form.Item style={{ display: this.state.showNotes ? "block" : "none" }}>
                        <i className={`text-small-grey-nopadding`} style={{ display: this.state.showNotes ? "block" : "none" }}>
                          Catatan
                        </i>
                        <Input.TextArea
                          value={this.state.notes}
                          onChange={(event) => this.onFieldChange("notes", event.target.value)}
                          autoSize={{ minRows: 3, maxRows: 5 }}
                          style={{ display: this.state.showNotes ? "block" : "none" }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row>
                    <Col xl={{ span: 24 }} lg={{ span: 24 }} md={{ span: 24 }} sm={{ span: 24 }} xs={{ span: 24 }} style={{ paddingTop: 10 }}>
                      <Button type="primary" onClick={this.onButtonStart} loading={this.state.submitting} danger disabled={this.state.disabledButton}>
                        {this.state.textButton} <CheckCircleOutlined />
                      </Button>
                    </Col>
                    {/* <small style={{ fontWeight: "bold", paddingTop: 20, display: this.state.showNotes ? "block" : "none" }}>
                      Test Drive {this.state.addTextDesc}
                      <table>
                        <tr>
                          <td>Waktu Mulai</td>
                          <td>:</td>
                          <td>{this.state.startTestDrive ? dayjs(this.state.startTestDrive).format("YYYY-MM-DD HH:mm") : ""}</td>
                        </tr>
                        <tr>
                          <td>Waktu Berakhir</td>
                          <td>:</td>
                          <td>{this.state.endTestDrive ? dayjs(this.state.endTestDrive).format("YYYY-MM-DD HH:mm") : ""}</td>
                        </tr>
                        <tr>
                          <td>Diproses oleh Agen</td>
                          <td>:</td>
                          <td>
                            {this.state.agenName} - {this.state.agenCode}
                          </td>
                        </tr>
                      </table>
                    </small> */}
                  </Row>
                </Form>
              </div>
            </div>
          </Col>
        </Layout>
        <FooterSection version={appVersion} />
      </React.Fragment>
    );
  }
}

export default withRouter(FormTestDriveCheck);
