import React, { Component } from "react";
import './FooterSection.css'
// import {Row} from "antd";
// import Col from "antd/lib/col";
// import {FacebookOutlined, YoutubeOutlined, InstagramOutlined, EnvironmentOutlined, PhoneOutlined, MailOutlined} from "@ant-design/icons";
// import {Link} from "react-router-dom";

class FooterSection extends Component<any, any> {
    private readonly initState!: any;

    constructor(props: any) {
        super(props);

        this.initState = {
            loading: false,
        }
        this.state = { ...this.initState }
    }

    render() {
        return (
            <React.Fragment>
                <footer className="footer-section">
                    {/*
                    <div className="container">
                        <div className="footer-content pt-5 pb-5">
                            <Row>
                                <Col className={`p-20`} xxl={{span: 8}} xl={{span: 8}} md={{span: 12}} sm={{span: 24}} xs={{span: 24}}>
                                    <div className="footer-widget">
                                        <div className="footer-logo">
                                            <Link to={`/`}>
                                                <img style={{height: 20}} src={`https://cdn.discordapp.com/attachments/944807503844241408/1064847948481040384/clipart2275093.png`} alt={`logo`}/>
                                            </Link>
                                        </div>
                                        <div className="footer-text">
                                            <p><b>Chery Bandung.</b> Kami adalah dealer resmi Merk Otomotif Chery di Indonesia untuk area Bandung Raya.</p>
                                        </div>
                                        <div className="footer-social-icon">
                                            <div className="footer-widget-heading">
                                                <h3>Follow Us</h3>
                                            </div>
                                            <a href="/"><FacebookOutlined/></a>
                                            <a href="/"><YoutubeOutlined/></a>
                                            <a href="/"><InstagramOutlined/></a>
                                        </div>
                                    </div>
                                </Col>
                                <Col className={`p-20`} xxl={{span: 8}} xl={{span: 8}} md={{span: 12}} sm={{span: 24}} xs={{span: 24}}>
                                    <div className="footer-widget">
                                        <div className="footer-widget-heading">
                                            <h3>Useful Links</h3>
                                        </div>
                                        <ul>
                                            <li><a href="/">Home</a></li>
                                            <li><a href="/">About Us</a></li>
                                            <li><a href="/">Vacancy</a></li>
                                            <li><a href="/">FAQ</a></li>
                                        </ul>
                                    </div>
                                </Col>
                                <Col className={`p-20`} xxl={{span: 8}} xl={{span: 8}} md={{span: 12}} sm={{span: 24}} xs={{span: 24}}>
                                    <div className="footer-widget">
                                        <div>
                                            <EnvironmentOutlined style={{color: "#fff", fontSize: "250%"}}/>
                                            <div className="cta-text">
                                                <h4>Find us</h4>
                                                <span>Jl Moh Toha no 136 Kota Bandung</span>
                                            </div>
                                        </div>
                                        <div className="single-cta">
                                            <PhoneOutlined style={{color: "#fff", fontSize: "250%"}}/>
                                            <div className="cta-text">
                                                <h4>Call us</h4>
                                                <span>(022) 5211217</span>
                                            </div>
                                        </div>
                                        <div className="single-cta">
                                            <MailOutlined style={{color: "#fff", fontSize: "250%"}}/>
                                            <div className="cta-text">
                                                <h4>Mail us</h4>
                                                <span>info@invento.technology</span>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                    */}

                    <div className="copyright-area">
                        <div className="container">
                            <div className="row">
                                <div className="col-xl-6 col-lg-6 text-center text-lg-left">
                                    <div className="copyright-text">
                                        <p className={"pl-20"}>CRM Amarta VIP {this?.props?.version ?? ""} &copy; {new Date().getFullYear()}, <a href="/">PT Trimitra Sukses Makmur</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </React.Fragment>
        );
    }
}

export default FooterSection;
