import axios from "axios";
import { workorderResponse } from "./types/workorderServicesTypes";
import { baseurlBengkel} from "../../config/apiConfig/apiConfig";

class WorkorderServices {
    private axios = axios.create({
        baseURL: baseurlBengkel,
    });

    public async getWorkorderList( workorder_code: string ) {      
      const workshop_code = workorder_code.slice(4, 9)

        const queries = {
          workshop_code:workshop_code,
          workorder_code: workorder_code,
          lite_response: false
        }
        try {
            return await this.axios.get<workorderResponse>('/workorder', {
                params: {
                    ...queries,
                }
            });
        } catch (e: any) {
            throw new Error(e);
        }
    }
}

export const workorderServices = new WorkorderServices();
