import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./redux";
// import Home from "./screen/home/Home";

import InvoiceItems from "./screen/invoices/InvoiceItems";
import InvoiceServices from "./screen/invoices/InvoiceServices";

import FormBookingService from "./screen/form/FormBookingService";

import FormTestDrive from "./screen/form/FormTestDrive";
import FormTestDriveCheck from "./screen/form/FormTestDriveCheck";

// import FormNewLeads from "./screen/form/FormNewLeads";
// import FormFreeLeads from "./screen/form/FormFreeLeads";
import FormFeedbackLead from "./screen/form/FormFeedbackLead";

import ProductSurvey from "./screen/form/ProductSurvey";

import NotAllowed from "./screen/home/NotAllowed";
import DonePage from "./screen/home/DonePage";
// import FormVenueActivityApplication from "./screen/form/FormVenueActivityApplication";

function App() {
  return (
    <Provider store={store}>
      <Router>
        <Routes>
          <Route path={"/rcpt/items/:service_code"} element={<InvoiceItems />} />
          <Route path={"/rcpt/services/:service_code"} element={<InvoiceServices />} />

          <Route path={"/testdrive/amartachery"} caseSensitive={false} element={<FormTestDrive />} />
          <Route path={"/testdrivecheck"} caseSensitive={false} element={<FormTestDriveCheck />} />

          <Route path={"/form-booking-service/:v/:d"} caseSensitive={false} element={<FormBookingService />} />

          {/* <Route path={"/newlead/:organizationCode"} caseSensitive={false} element={<FormNewLeads />} />
          <Route path={"/freelead/:organizationCode/:oaName?"} caseSensitive={false} element={<FormFreeLeads />} /> */}
          <Route path={"/leadfb/:clientPhone"} caseSensitive={false} element={<FormFeedbackLead />} />

          <Route path={"/vehicle-survey/testdrive/:code"} caseSensitive={false} element={<ProductSurvey />} />

          <Route path={"/finish-booking"} element={<DonePage />} />
          {/* <Route path={"/activity-application-form/:organizationCode"} element={<FormVenueActivityApplication />} /> */}
          <Route path={"/not-allowed"} element={<NotAllowed />} />
          <Route path="/*" element={<NotAllowed />} />
        </Routes>
      </Router>
    </Provider>
  );
}

export default App;
