import React, { Component } from "react";
import { Avatar } from "antd";
import { Radio } from "antd";

class SatisfactionLevel extends Component<any, any> {
  private readonly initState!: any;

  constructor(props: any) {
    super(props);

    this.initState = {
      satisfactionLevel: "",
    };
    this.state = { ...this.initState };
    // this.handleFeedbackChange = this.handleFeedbackChange.bind(this);
  }

  handleFeedbackChange = (e: any) => {
    const satisfactionLevel = e.target.value;
    this.setState({ satisfactionLevel: satisfactionLevel });
    this.props.onSatisfactionLevelChangeProps(e);
  };

  render() {
    return (
      <>
        <Radio.Group onChange={this.handleFeedbackChange} value={this.state.satisfactionLevel} style={{ display: "flex", justifyContent: "center" }}>
          <Radio value="GOOD">
            <div>
              <center>
                <Avatar style={{ verticalAlign: "middle", fontSize: 35 }} size="large" src="/assets/img/good.png" />
                <br />
                <strong>Puas</strong>
              </center>
            </div>
          </Radio>
          <Radio value="NETRAL">
            <center>
              <Avatar style={{ verticalAlign: "middle", fontSize: 35 }} size="large" src="/assets/img/netral.png" />
              <br />
              <strong>Biasa saja</strong>
            </center>
          </Radio>
          <Radio value="BAD">
            <center>
              <Avatar style={{ verticalAlign: "middle", fontSize: 35 }} size="large" src="/assets/img/bad.png" />
              <br />
              <strong>Tidak Puas</strong>
            </center>
          </Radio>
        </Radio.Group>
      </>
    );
  }
}

export default SatisfactionLevel;
