import React, { Component } from "react";
import { withRouter } from "../../hoc/withRouter";
import dayjs from "dayjs";

import MyProfileLoginHeader from "../../component/layouts/MyProfileLoginHeader";
import FooterSection from "../../component/pageSection/FooterSection";

import { crmAmartaVipServices } from "../../services/amartaVip/CrmAmartaVipServices";
import { bookingAmartaVipServices } from "../../services/amartaVip/BookingAmartaVipServices";

import customParseFormat from "dayjs/plugin/customParseFormat";
import { Layout, Button, Col, Modal, Row, Spin, DatePicker, Alert, Form } from "antd";
import type { RangePickerProps } from "antd/es/date-picker";
import { CheckCircleOutlined } from "@ant-design/icons";

import { appVersion } from "../../config/appDataConfig/appConfig";
import { FormBookingServiceStates } from "./types/FormBookingServiceTypes";
import ListAppointment from "../../component/layouts/ListAppointment";

dayjs.extend(customParseFormat);
const dateFormat = "YYYY-MM-DD";

const disabledFirstServiceDate: RangePickerProps["disabledDate"] = (current) => {
  return current && current < dayjs().add(29, "day").endOf("day");
};
const disabledSecondServiceDate: RangePickerProps["disabledDate"] = (current) => {
  return current && current < dayjs().add(89, "day").endOf("day");
};
const disabledThirdServiceDate: RangePickerProps["disabledDate"] = (current) => {
  return current && current < dayjs().add(239, "day").endOf("day");
};
const disabledFourthServiceDate: RangePickerProps["disabledDate"] = (current) => {
  return current && current < dayjs().add(359, "day").endOf("day");
};
const disabledFifthServiceDate: RangePickerProps["disabledDate"] = (current) => {
  return current && current < dayjs().add(419, "day").endOf("day");
};
const disabledSixthServiceDate: RangePickerProps["disabledDate"] = (current) => {
  return current && current < dayjs().add(479, "day").endOf("day");
};
const disabledDateTime = () => ({
  disabledHours: () => [0, 1, 2, 3, 4, 5, 6, 7, 8, 15, 16, 17, 18, 19, 20, 21, 22, 23],
});

export interface FormBookingServiceProps {}

class FormBookingService extends Component<FormBookingServiceProps & any, FormBookingServiceStates> {
  constructor(props: FormBookingServiceProps & any) {
    super(props);

    this.state = {
      submitting: false,
      freezeStatus: false,
      processing: false,

      dataClient: {},

      firstServiceDate: dayjs(this.props.match.params.d).add(30, "day").format(dateFormat),
      secondServiceDate: dayjs(this.props.match.params.d).add(90, "day").format(dateFormat),
      thirdServiceDate: dayjs(this.props.match.params.d).add(240, "day").format(dateFormat),
      fourthServiceDate: dayjs(this.props.match.params.d).add(360, "day").format(dateFormat),
      fifthServiceDate: dayjs(this.props.match.params.d).add(420, "day").format(dateFormat),
      sixthServiceDate: dayjs(this.props.match.params.d).add(480, "day").format(dateFormat),

      firstServiceTime: dayjs().hour(10).minute(0).format("HH:mm"),
      secondServiceTime: dayjs().hour(10).minute(0).format("HH:mm"),
      thirdServiceTime: dayjs().hour(10).minute(0).format("HH:mm"),
      fourthServiceTime: dayjs().hour(10).minute(0).format("HH:mm"),
      fifthServiceTime: dayjs().hour(10).minute(0).format("HH:mm"),
      sixthServiceTime: dayjs().hour(10).minute(0).format("HH:mm"),

      dayDiff: 0,
      displayForm: "none",
      displayInvoice: "none",
      dataAppointment: [],
    };
  }

  async fetchData() {
    try {
      const dataFetchClient = await crmAmartaVipServices.getVehicle({
        vehicle_engine_number: this.props.match.params.v,
      });

      const dataClient: any | undefined = dataFetchClient.data.data;

      if (dataClient.length > 0) {
        const dataAppointment = await bookingAmartaVipServices.getBookingService({ license_plate: dataClient[0].license_plate });
        console.log("dataAppointment", dataAppointment);

        if (dataAppointment.success === true) {
          this.setState({
            displayForm: "none",
            displayInvoice: "block",
            dataAppointment: dataAppointment.data,
          });
        } else {
          this.setState({
            displayForm: "block",
            displayInvoice: "none",
            dataAppointment: [],
          });
        }

        const date1 = dayjs(this.props.match.params.d);
        const date2 = dayjs();
        const days = date2.diff(date1, "day");

        this.setState({
          dataClient,
          dayDiff: days,
        });
      } else {
        // this.props.history.push("/not-allowed");
      }
    } catch (e) {
      console.log(e);
      // this.props.history.push("/not-allowed");
    }
  }

  async componentDidMount() {
    this.fetchData();
  }

  onDoneStep = async () => {
    const currentState: any = { ...this.state };

    if (currentState.firstServiceDate === "") {
      Modal.error({ title: "Error", content: "Mohon Lengkapi setidaknya service pertama harus diisi" });
      return;
    }

    this.setState({ submitting: true });

    const objectDefault = {
      client_name: currentState.dataClient.id_card_name,
      client_phone_number: currentState.dataClient.phone_number,
      source: "https://crm.amarta.vip/",
      vehicle_license_plate: currentState.dataClient.license_plate,
      vehicle_model: currentState.dataClient.vehicle_model_name,
      agen_code: "",
      type: "KPB 1",
      vendor_code: currentState.dataClient.order_number.slice(8, 13),
      vendor_name: "BENGKEL AMARTAHONDA",
      appointment_time: currentState.firstServiceDate + " " + currentState.firstServiceTime,
      vehicle_odometer: 0,
      note: currentState.dayDiff < 1 ? "Selamat anda mendapatkan 2x perawatan oli gratis" : "Selamat anda mendapatkan 1x perawatan oli gratis",
    };

    const post_data = [];

    if (currentState.secondServiceDate && currentState.secondServiceTime) {
      const objFirstService = { ...objectDefault };
      objFirstService.type = "KPB 1";
      objFirstService.appointment_time = currentState.firstServiceDate + " " + currentState.firstServiceTime;

      post_data.push(objFirstService);
    }

    if (currentState.secondServiceDate && currentState.secondServiceTime) {
      const objSecondService = { ...objectDefault };
      objSecondService.type = "KPB 2";
      objSecondService.appointment_time = currentState.secondServiceDate + " " + currentState.secondServiceTime;

      post_data.push(objSecondService);
    }

    if (currentState.thirdServiceDate && currentState.thirdServiceTime) {
      const objThirdService = { ...objectDefault };
      objThirdService.type = "KPB 3";
      objThirdService.appointment_time = currentState.thirdServiceDate + " " + currentState.thirdServiceTime;

      post_data.push(objThirdService);
    }

    if (currentState.fourthServiceDate && currentState.fourthServiceTime) {
      const objFourthService = { ...objectDefault };
      objFourthService.type = "KPB 4";
      objFourthService.appointment_time = currentState.fourthServiceDate + " " + currentState.fourthServiceTime;

      post_data.push(objFourthService);
    }

    if (currentState.dayDiff < 1 && currentState.fourthServiceDate && currentState.fourthServiceTime) {
      const objFifthService = { ...objectDefault };
      objFifthService.type = "KPB AMT 1";
      objFifthService.appointment_time = currentState.fourthServiceDate + " " + currentState.fourthServiceTime;

      post_data.push(objFifthService);
    }

    if (currentState.dayDiff < 1 && currentState.fourthServiceDate && currentState.fourthServiceTime) {
      const objSixthService = { ...objectDefault };
      objSixthService.type = "KPB AMT 2";
      objSixthService.appointment_time = currentState.fourthServiceDate + " " + currentState.fourthServiceTime;

      post_data.push(objSixthService);
    }

    bookingAmartaVipServices.postBookingService(post_data).then((dataFetchClient) => {
      this.setState({
        submitting: false,
      });

      this.props.history.push("/finish-booking");
    });
  };

  onFieldChange = <
    T extends keyof Pick<
      any,
      | "firstServiceDate"
      | "firstServiceTime"
      | "secondServiceDate"
      | "secondServiceTime"
      | "thirdServiceDate"
      | "thirdServiceTime"
      | "fourthServiceDate"
      | "fourthServiceTime"
      | "fifthServiceDate"
      | "fifthServiceTime"
      | "sixthServiceDate"
      | "sixthServiceTime"
    >
  >(
    target: T,
    value: any
  ) => {
    const currentState: any = { ...this.state };
    switch (target) {
      case "firstServiceDate":
        currentState.firstServiceDate = value;
        break;
      case "firstServiceTime":
        currentState.firstServiceTime = value;
        break;
      case "secondServiceDate":
        currentState.secondServiceDate = value;
        break;
      case "secondServiceTime":
        currentState.secondServiceTime = value;
        break;
      case "thirdServiceDate":
        currentState.thirdServiceDate = value;
        break;
      case "thirdServiceTime":
        currentState.thirdServiceTime = value;
        break;
      case "fourthServiceDate":
        currentState.fourthServiceDate = value;
        break;
      case "fourthServiceTime":
        currentState.fourthServiceTime = value;
        break;
    }

    this.setState({
      ...currentState,
    });
  };

  render() {
    return (
      <React.Fragment>
        <Layout className="layout">
          <div style={{ display: this.state.displayForm }}>
            <Row style={{ backgroundColor: "#fff", paddingBottom: 5, borderBottom: "#f0f2f5 2px solid" }}>
              <Col xl={{ span: 20, offset: 2 }} lg={{ span: 20, offset: 2 }} md={{ span: 24 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                <MyProfileLoginHeader title={"Booking Service"} style={{ fontSize: 10 }} />
              </Col>
            </Row>
            <Col xl={{ span: 16, offset: 4 }} lg={{ span: 18, offset: 2 }} md={{ span: 24 }} sm={{ span: 24 }} xs={{ span: 24 }}>
              <div style={{ backgroundColor: "#fff", padding: "2vh" }}>
                <div style={this.state.processing ? { textAlign: "center", padding: 20, minHeight: 200 } : { display: "none" }}>
                  <Spin style={{ marginTop: 20 }} size={"large"} tip="Loading..." />
                </div>
                <div style={!this.state.processing ? {} : { display: "none" }}>
                  <Form layout="vertical">
                    <Row>
                      <Col xl={{ span: 24 }} lg={{ span: 24 }} md={{ span: 24 }} sm={{ span: 24 }} xs={{ span: 24 }} style={{ padding: 5 }}>
                        {/* <Alert message="Jadwalkan service kendaraan baru anda dan dapatkan perawatan Oli Gratis !" type="error" /> */}
                        <Alert message={`Selamat, Anda berhak mendapatkan ${this.state.dayDiff < 1 ? 2 : 1} perawatan Oli Gratis, Jadwalkan service kendaraan baru sekarang! `} type="error" />
                      </Col>
                      <Col xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 24 }} sm={{ span: 24 }} xs={{ span: 24 }} style={{ padding: 5, margin: "auto" }}>
                        <img alt={''} src="/assets/img/oli.jpg" style={{ width: "100%" }}></img>
                      </Col>
                      <Col xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 24 }} sm={{ span: 24 }} xs={{ span: 24 }} style={{ padding: 5 }}>
                        <Row>
                          <Col xl={{ span: 24 }} lg={{ span: 24 }} md={{ span: 24 }} sm={{ span: 24 }} xs={{ span: 24 }} style={{ paddingLeft: 10, paddingRight: 10 }}>
                            <Row>
                              <Col xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 12 }} xs={{ span: 12 }} style={{ paddingRight: 5 }}>
                                <Form.Item>
                                  <i className={`text-small-grey-nopadding`}>Waktu Service Pertama</i>
                                  <DatePicker
                                    name="firstServiceDate"
                                    format={"YYYY-MM-DD"}
                                    style={{ width: "100%" }}
                                    disabledDate={disabledFirstServiceDate}
                                    defaultValue={dayjs(this.state.firstServiceDate)}
                                    onChange={(date, dateString) => this.onFieldChange("firstServiceDate", dateString)}
                                  />
                                </Form.Item>
                              </Col>
                              <Col xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 12 }} xs={{ span: 12 }}>
                                <Form.Item>
                                  <i className={`text-small-grey-nopadding`}>&nbsp;</i>
                                  <DatePicker
                                    picker="time"
                                    name="firstServiceTime"
                                    format="HH:mm"
                                    onChange={(date, dateString) => this.onFieldChange("firstServiceTime", dateString)}
                                    disabledTime={disabledDateTime}
                                    defaultValue={dayjs().hour(10).minute(0)}
                                    style={{ width: "100%" }}
                                  />
                                </Form.Item>
                              </Col>
                            </Row>
                          </Col>
                          <Col xl={{ span: 24 }} lg={{ span: 24 }} md={{ span: 24 }} sm={{ span: 24 }} xs={{ span: 24 }} style={{ paddingLeft: 10, paddingRight: 10 }}>
                            <Row>
                              <Col xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 12 }} xs={{ span: 12 }} style={{ paddingRight: 5 }}>
                                <Form.Item>
                                  <i className={`text-small-grey-nopadding`}>Waktu Service Kedua</i>
                                  <DatePicker
                                    name="secondServiceDate"
                                    format={"YYYY-MM-DD"}
                                    style={{ width: "100%" }}
                                    disabledDate={disabledSecondServiceDate}
                                    defaultValue={dayjs(this.state.secondServiceDate)}
                                    onChange={(date, dateString) => this.onFieldChange("secondServiceDate", dateString)}
                                  />
                                </Form.Item>
                              </Col>
                              <Col xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 12 }} xs={{ span: 12 }}>
                                <Form.Item>
                                  <i className={`text-small-grey-nopadding`}>&nbsp;</i>
                                  <DatePicker
                                    picker="time"
                                    name="secondServiceTime"
                                    format="HH:mm"
                                    onChange={(date, dateString) => this.onFieldChange("secondServiceTime", dateString)}
                                    disabledTime={disabledDateTime}
                                    defaultValue={dayjs().hour(10).minute(0)}
                                    style={{ width: "100%" }}
                                  />
                                </Form.Item>
                              </Col>
                            </Row>
                          </Col>
                          <Col xl={{ span: 24 }} lg={{ span: 24 }} md={{ span: 24 }} sm={{ span: 24 }} xs={{ span: 24 }} style={{ paddingLeft: 10, paddingRight: 10 }}>
                            <Row>
                              <Col xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 12 }} xs={{ span: 12 }} style={{ paddingRight: 5 }}>
                                <Form.Item>
                                  <i className={`text-small-grey-nopadding`}>Waktu Service Ketiga</i>
                                  <DatePicker
                                    name="thirdServiceDate"
                                    format={"YYYY-MM-DD"}
                                    style={{ width: "100%" }}
                                    disabledDate={disabledThirdServiceDate}
                                    defaultValue={dayjs(this.state.thirdServiceDate)}
                                    onChange={(date, dateString) => this.onFieldChange("thirdServiceDate", dateString)}
                                  />
                                </Form.Item>
                              </Col>
                              <Col xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 12 }} xs={{ span: 12 }}>
                                <Form.Item>
                                  <i className={`text-small-grey-nopadding`}>&nbsp;</i>
                                  <DatePicker
                                    picker="time"
                                    name="thirdServiceTime"
                                    format="HH:mm"
                                    onChange={(date, dateString) => this.onFieldChange("thirdServiceTime", dateString)}
                                    disabledTime={disabledDateTime}
                                    defaultValue={dayjs().hour(10).minute(0)}
                                    style={{ width: "100%" }}
                                  />
                                </Form.Item>
                              </Col>
                            </Row>
                          </Col>
                          <Col xl={{ span: 24 }} lg={{ span: 24 }} md={{ span: 24 }} sm={{ span: 24 }} xs={{ span: 24 }} style={{ paddingLeft: 10, paddingRight: 10 }}>
                            <Row>
                              <Col xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 12 }} xs={{ span: 12 }} style={{ paddingRight: 5 }}>
                                <Form.Item>
                                  <i className={`text-small-grey-nopadding`}>Waktu Service Keempat</i>
                                  <DatePicker
                                    name="fourthServiceDate"
                                    format={"YYYY-MM-DD"}
                                    style={{ width: "100%" }}
                                    disabledDate={disabledFourthServiceDate}
                                    defaultValue={dayjs(this.state.fourthServiceDate)}
                                    onChange={(date, dateString) => this.onFieldChange("fourthServiceDate", dateString)}
                                  />
                                </Form.Item>
                              </Col>
                              <Col xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 12 }} xs={{ span: 12 }}>
                                <Form.Item>
                                  <i className={`text-small-grey-nopadding`}>&nbsp;</i>
                                  <DatePicker
                                    picker="time"
                                    name="fourthServiceTime"
                                    format="HH:mm"
                                    onChange={(date, dateString) => this.onFieldChange("fourthServiceTime", dateString)}
                                    disabledTime={disabledDateTime}
                                    defaultValue={dayjs().hour(10).minute(0)}
                                    style={{ width: "100%" }}
                                  />
                                </Form.Item>
                              </Col>
                            </Row>
                          </Col>
                          <Col
                            xl={{ span: 24 }}
                            lg={{ span: 24 }}
                            md={{ span: 24 }}
                            sm={{ span: 24 }}
                            xs={{ span: 24 }}
                            style={{ display: this.state.dayDiff < 1 ? "block" : "none", paddingLeft: 10, paddingRight: 10 }}
                          >
                            <Row>
                              <Col xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 12 }} xs={{ span: 12 }} style={{ paddingRight: 5 }}>
                                <Form.Item>
                                  <i className={`text-small-grey-nopadding`}>Waktu Service Kelima</i>
                                  <DatePicker
                                    name="fifthServiceDate"
                                    format={"YYYY-MM-DD"}
                                    style={{ width: "100%" }}
                                    disabledDate={disabledFifthServiceDate}
                                    defaultValue={dayjs(this.state.fifthServiceDate)}
                                    onChange={(date, dateString) => this.onFieldChange("fifthServiceDate", dateString)}
                                  />
                                </Form.Item>
                              </Col>
                              <Col xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 12 }} xs={{ span: 12 }}>
                                <Form.Item>
                                  <i className={`text-small-grey-nopadding`}>&nbsp;</i>
                                  <DatePicker
                                    picker="time"
                                    name="fifthServiceTime"
                                    format="HH:mm"
                                    onChange={(date, dateString) => this.onFieldChange("fifthServiceTime", dateString)}
                                    disabledTime={disabledDateTime}
                                    defaultValue={dayjs().hour(10).minute(0)}
                                    style={{ width: "100%" }}
                                  />
                                </Form.Item>
                              </Col>
                            </Row>
                          </Col>
                          <Col
                            xl={{ span: 24 }}
                            lg={{ span: 24 }}
                            md={{ span: 24 }}
                            sm={{ span: 24 }}
                            xs={{ span: 24 }}
                            style={{ display: this.state.dayDiff < 1 ? "block" : "none", paddingLeft: 10, paddingRight: 10 }}
                          >
                            <Row>
                              <Col xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 12 }} xs={{ span: 12 }} style={{ paddingRight: 5 }}>
                                <Form.Item>
                                  <i className={`text-small-grey-nopadding`}>Waktu Service Keenam</i>
                                  <DatePicker
                                    name="sixthServiceDate"
                                    format={"YYYY-MM-DD"}
                                    style={{ width: "100%" }}
                                    disabledDate={disabledSixthServiceDate}
                                    defaultValue={dayjs(this.state.sixthServiceDate)}
                                    onChange={(date, dateString) => this.onFieldChange("sixthServiceDate", dateString)}
                                  />
                                </Form.Item>
                              </Col>
                              <Col xl={{ span: 12 }} lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 12 }} xs={{ span: 12 }}>
                                <Form.Item>
                                  <i className={`text-small-grey-nopadding`}>&nbsp;</i>
                                  <DatePicker
                                    picker="time"
                                    name="sixthServiceTime"
                                    format="HH:mm"
                                    onChange={(date, dateString) => this.onFieldChange("sixthServiceTime", dateString)}
                                    disabledTime={disabledDateTime}
                                    defaultValue={dayjs().hour(10).minute(0)}
                                    style={{ width: "100%" }}
                                  />
                                </Form.Item>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <Row>
                          <Col xl={{ span: 8 }} lg={{ span: 8 }} md={{ span: 12 }} sm={{ span: 24 }} xs={{ span: 24 }} style={{ padding: 10 }}>
                            <Button type="primary" onClick={this.onDoneStep} loading={this.state.submitting} danger>
                              Simpan Jadwal <CheckCircleOutlined />
                            </Button>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </div>
            </Col>
          </div>
          <div style={{ display: this.state.displayInvoice }}>
            <ListAppointment data={this.state.dataAppointment}></ListAppointment>
          </div>
        </Layout>
        <FooterSection version={appVersion} />
      </React.Fragment>
    );
  }
}

export default withRouter(FormBookingService);
