import React, { Component } from 'react';
import Layout from "antd/lib/layout";
import Col from "antd/lib/col";

class NotAllowed extends Component<any, any> {
    private readonly initState!: any;

    constructor(props: any) {
        super(props);
        this.initState = {
            areaCode: ""
        }
        this.state = { ...this.initState }
    }

    componentDidMount() {
    }

    render() {
        return (
            <React.Fragment>
                <Layout className="layout">
                    <Col xxl={{ span: 16, offset: 4 }} xl={{ span: 20, offset: 2 }} lg={{ span: 20, offset: 2 }} md={{ span: 20, offset: 2 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                        <div style={{ marginTop: 150, textAlign: "center" }}>
                            <h1>Error : Not Authorized</h1>
                            <p>Anda tidak bisa mengakses halaman ini</p>
                            <br /><br /><br /><br />
                        </div>
                    </Col>
                </Layout >
            </React.Fragment >
        );
    }
}

export default NotAllowed;
